<template>
  <div class="custormers-search">

    <v-row >
      <v-col class="text-right">
        <v-btn :to="{name: 'customer.add', query: {'oid': this.organization.id}}" small>
          <v-icon left>mdi-plus</v-icon>
          {{ $t('customer.add-customer')}}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <search-field
            :placeholder="$t('customer.search-placeholder')"
            :search.sync="search"
            clearable
        />
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-10">
      <v-col>
        <v-data-table
            class="cp-list"
            :headers="headers"
            :items="items"
            :loading="loading"
            :options.sync="options"
            :server-items-length="total"
            :footer-props="{
              'items-per-page-options': [
                  20, 50, 100
              ]
            }"
        >

          <template #item.evcoId="{item, value}">
            <router-link class="click" :to="{name: 'customer.detail', params: {id: item.id}}">
              {{ value }}
            </router-link>
          </template>

          <template #item.number="{value}">
            <span class="number">{{ value }}</span>
          </template>

          <template #item.email="{value}">
            {{ value ? value : '-'}}
          </template>

          <template #item.lastname="{item}">
            <router-link class="click" :to="{name: 'customer.detail', params: {id: item.id}}">
              <span class="firstname">{{ item.firstname }} {{ item.lastname }}</span> <span class="company" v-if="item.company">({{ item.company }})</span>
            </router-link>
          </template>

          <template #item.loginMode="{item}">
            {{ item.email ? $t('email') : '' }}
            {{ item.email && item.phone ? '/' : '' }}
            {{ item.phone ? $t('phone') : '' }}
          </template>

          <template #item.tokens="{value}">
            <template v-if="value && value.length > 0">
              {{ value.length }} Tag{{ value.length > 1 ? 's' : '' }}
            </template>
            <template v-else>
              -
            </template>
          </template>



          <template #item.actions="{item}">
            <v-tooltip
              bottom
              nudge-bottom="5"
              v-if="currentUser.isZE"
            >
              <template #activator="{on, attrs}">
                <v-btn
                    v-bind="attrs"
                    small
                    v-on="on"
                    text class="mr-3"
                    @click.stop="addMember(item)"
                    :disabled="item.members.length > 0"
                >
                  <v-icon>mdi-account-plus</v-icon>
                </v-btn>
              </template>
              {{ $t('customer.add-as-member') }}
            </v-tooltip>
          </template>

        </v-data-table>
      </v-col>
    </v-row>






  </div>
</template>

<script>


import CustomerRepository from "@repository/CustomerRepository";
import MemberRepository from "@repository/MemberRepository";
import Tools from "@includes/tools";

export default {
  name: 'CustomerSearch',
  data() {
    return {
      loading: false,
      search: null,
      items: [],
      total: 0,
      options: null,

    }
  },

  mounted() {
    if(!this.currentUser.permissions.includes('CUSTOMER_READ')) {
      this.$router.replace({name: 'dashboard'})
      return
    }
  },

  watch: {
    search(val) {
      if(this.options.page > 1) {
        this.options.page = 1
      } else {
        this.searchData()
      }
    },

    options(val) {
      this.searchData()
    }
  },

  computed: {
    Tools() {
      return Tools
    },
    headers() {
      const headers =  [
        {text: this.$t('evco-id'), value: 'evcoId'},
        {text: this.$t('organization.organization'), value: 'organization.name'},
        {text: this.$t('name'), value: 'lastname'},
        {text: this.$t('email'), value: 'email'},
        {text: this.$t('customer.login-mode'), value: 'loginMode', sortable: false},
        {text: this.$tc('rfid.rfid', 2), value: 'tokens'},
      ]
      if(this.currentUser.isZE) {
        headers.push({value: 'actions', width: 50},)
      }
      return headers
    },

    organization() {
      return this.$parent.organization ?? null
    }
  },

  methods: {
    searchData() {

      let options = this.options



      this.loading = true
      let query = this.search;
      let offset = options.itemsPerPage * options.page - options.itemsPerPage;
      let limit = options.itemsPerPage;
      let sortBy = options.sortBy.length > 0 ? options.sortBy[0] : 'createdAt'
      let sortOrder = options.sortDesc.length > 0 && options.sortDesc[0] ? 'desc' : 'asc'


      if(sortBy === 'tokens') {
        sortBy = 'tokens.uid'
      }

      this.loading = true
      CustomerRepository.search({
            searchText: query,
            offset,
            limit,
            sortBy,
            sortOrder,
            organizationId: this.organization?.id,
            checkMembership: true,
      }).then(result => {
        this.total = result.total
        this.items = result.items
        this.loading = false
      }).catch(err => {
        this.$dialog.notify.error(this.$t(err.response?.data?.message || err.message))
        this.loading = false
      })
    },

    addMember(item) {
      this.showLoading(true)
      MemberRepository.createFromCustomer(this.organization.id, item.id).then(() => {
        this.showLoading(false)
        item.members = [
            true
        ]
      }).catch(e => {
        this.showLoading(false)
        this.notifyError(e)
      })
    },
  }
}
</script>

<style lang="scss">
.custormers-search {
  .v-data-table {
    td {
      cursor: pointer;
      .click{
        text-decoration: none;
        color: #71A723;
      }
      .number {
        color: #E53C22;
        font-weight: bold;
      }
      .lastname, .firstname {
        color: #E53C22;
        font-weight: bold;
      }
      .company {
        font-weight: 100;
      }
    }
  }
}


</style>
