import Vue from 'vue'

class MemberTypeRepository {

    get(id) {
        console.log('ID:'+id)
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/member-type/' + id,
                method: 'get',
            })
                .then(response => {


                    resolve(response.data)
                }).catch(err => {
                reject(err)
            })
        })
    }

    create(data) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/member-type/create',
                method: 'post',
                data,
            })
                .then(response => {
                    resolve(response.data)
                }).catch(err => {
                reject(err)
            })
        })
    }

    update(id, data) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/member-type/update/' + id,
                method: 'put',
                data,
            })
                .then(response => {
                    resolve(response.data)
                }).catch(err => {
                reject(err)
            })
        })
    }

    delete(id) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/member-type/delete/' + id,
                method: 'delete',
            })
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    search(data) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/member-type/search',
                method: 'post',
                data,
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default new MemberTypeRepository()