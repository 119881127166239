<template>
  <div class="subscriptions-search">


    <headline>
      {{ $tc('subscription.subscription', 2) }}
    </headline>
    <v-row >
      <v-col class="text-right">
        <v-btn small @click="$refs.subscribeModal.open()">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('subscription.add')}}
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col>
        <v-data-table
            class="subscriptions-list"
            :headers="headers"
            :loading="loading"
            :items="subscriptions"
            :options.sync="options"
            :server-items-length="total"
        >

          <template #item.billingCycle="{item}">
            {{ $t('enums.billing-cycle.' + item.billingCycle) }}
          </template>

          <template #item.activationStartDate="{item}">
            {{ item.activationStartDate | formatDate($t('format_date'))}}
          </template>

          <template #item.commitmentDuration="{item}">
            {{ item.commitmentDuration }} {{ $t('month')}}
          </template>
          <template #item.enabled="{value}">
            <v-icon v-if="value">mdi-check</v-icon>
          </template>
          <template #item.options="{item}">
            <v-btn icon :to="{name: 'organizations.subscriptions.edit', params: {subscriptionId: item.id}}">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click.prevent="delSubscription(item)" small icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>

        </v-data-table>

      </v-col>
    </v-row>

    <subscription-licences-list
        v-if="!loading"
        :organizationId="organization.id"
        :subscription-id="selectedSubscriptionId"
    >
      <template #filters v-if="subscriptions.length > 1">
        <v-select
          v-model="selectedSubscriptionId"
          :label="$tc('subscription.filter-by-subscription')"
          :items="subscriptions"
          item-text="subscriptionPlan.name"
          item-value="id"
          hide-details
          clearable
        >
        </v-select>
      </template>
    </subscription-licences-list>

    <organization-subscribe-plan-modal
        ref="subscribeModal"
        :organization-id="organization.id"
        @subscribed="onSubscribed"
    />

  </div>
</template>

<script>
import SubscriptionRepository from "@repository/SubscriptionRepository";
import OrganizationSubscribePlanModal from "@/components/modals/OrganizationSubscribePlanModal.vue";
import SubscriptionLicencesList from "@/components/elements/SubscriptionLicencesList.vue";

export default {

  components: {
    OrganizationSubscribePlanModal,
    SubscriptionLicencesList,
  },

  data() {
    return {
      loading: false,
      selectedItem: null,
      options: null,
      selectedSubscriptionId: null,
      total: 0,
      headers: [
        {text: this.$tc('subscription.subscription'), value: 'subscriptionPlan.name'},
        {text: this.$t('subscription.billing-cycle'), value: 'billingCycle'},
        // {text: this.$t('subscription.commitment-duration'), value: 'commitmentDuration'},
        {text: this.$t('subscription.activation-start-date'), value: 'activationStartDate'},
        {text: this.$t('enable'), value: 'enabled'},
        {value: 'options'},
      ],
      subscriptions: [],
    }
  },

    // 'commitmentDuration' => $subscription->commitmentDuration(),
    // 'price' => $subscription->price(),
    // 'refundHandling' => $subscription->refundHandling(),
    // 'billingStartDate' => $subscription->billingStartDate(),
    // 'activationStartDate' => $subscription->activationStartDate(),

  mounted() {

  },

  watch: {

    options() {
      this.loadData()
    },

    subscriptions(subscriptions) {
      if(this.selectedSubscriptionId) {
        if(!subscriptions.find(item => item.id === this.selectedSubscriptionId)) {
          this.selectedSubscriptionId = null
        }
      }
    },

  },

  computed: {
    organization() {
      return this.$parent.organization ?? null
    }
  },

  methods: {
    loadData() {

      let data = {
        organizationId: this.organization?.id
      }

      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage


      if (this.options.sortBy.length > 0) {
        data.orderBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.orderDirection = this.options.sortDesc[0] ? 'desc' : 'asc'
      }

      this.loading = true
      SubscriptionRepository.search(data).then(response => {
        this.total = response.total
        this.subscriptions = response.items
        this.loading = false
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
        this.loading = false
      })
    },

    onSubscribed(item) {
      this.loadData()
      this.$parent.loadTabs()
    },
    delSubscription(item) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if(result) {
          this.loading = true
          SubscriptionRepository.delete(item.id).then(response => {
            this.$dialog.notify.success(this.$t('update-success'))
            this.loadData()
          }).catch(error => {
            this.notifyError(error)
            this.loading = false
          })
        }
      })
    },
  },

}
</script>

<style lang="scss">
.subscriptions-search {

}
</style>
