<template>
  <div>
    <div class="member-wrapper" v-if="invitation.id">

      <!-- Breadcrumb -->
      <v-breadcrumbs
          class="mt-3 level-2"
          :items="[
              {text: $tc('member.member', 2), to: {name: 'organizations.members'}, exact: true},
              {text: $t('member.invitation.manage'), to: {name: 'organizations.members.invitations'}, exact: true},
              {text:  invitationName},
          ]"
          divider=">"
      ></v-breadcrumbs>

      <!-- Infos générales -->

      <headline>
        {{ $tc("organization.general-information") }}
      </headline>

      <div class="grid">
        <v-form ref="form" @submit.prevent="save">

          <v-row>
            <v-col class="label" sm="3">
              {{ $t('status') }} :
            </v-col>
            <v-col>
              {{ invitation.status ? $t('member.status.' + invitation.status) : '-' }}
              <v-btn
                  v-if="isAlreadySent"
                  small
                  text
                  outlined
                  :href="`/member/invitation/${invitation.invitationToken}`"
                  target="_blank"
                  class="ml-5"
              >
                {{ $t('member.invitation.invitation-link') }}
              </v-btn>


            </v-col>

            <v-col cols="auto">

              <v-btn class="ml-5" small @click="resendInvitation" v-if="invitation.status === 'PENDING'">
                <v-icon left>mdi-refresh</v-icon>
                {{ $t('member.invitation.resend') }}
              </v-btn>
            </v-col>
          </v-row>

          <!-- Type -->
          <v-row>
            <v-col class="label" sm="3">
              {{ $t('type') }}
            </v-col>
            <v-col sm="3">
              <v-select
                  v-model="invitation.memberType.id"
                  :items="memberTypes"
                  item-text="name"
                  item-value="id"
                  hide-details
                  clearable
              ></v-select>
            </v-col>
          </v-row>

          <!-- First name -->
          <v-row>
            <v-col class="label" sm="3">
              {{ $t('firstname') }}
            </v-col>
            <v-col class="label" sm="3">
              <v-text-field
                  v-model="invitation.firstname"
                  hide-details
              />
            </v-col>

            <v-col class="label" sm="3">
              {{ $t('lastname') }}
            </v-col>
            <v-col class="label" sm="3">
              <v-text-field
                  v-model="invitation.lastname"
                  hide-details
              />
            </v-col>
          </v-row>

          <!-- Matricule -->
          <v-row>
            <v-col class="label" sm="3">
              {{ $t('member.external-reference') }}
            </v-col>
            <v-col class="label" sm="3">
              <v-text-field
                  v-model="invitation.externalReference"
                  hide-details
              />
            </v-col>

            <v-col class="label" sm="3">
              {{ $t('vehicle-registration') }}
            </v-col>
            <v-col class="label" sm="3">
              <v-text-field
                  v-model="invitation.vehicleRegistrationNumber"
                  hide-details
              />
            </v-col>
          </v-row>



          <custom-fields
              v-if="customFields"
              :fields="customFields"
              v-model="invitation.customFields"
              :col-field="9"
          />

          <!-- Crée le... par... -->
          <v-row>
            <v-col class="label" sm="3">
              {{ $t('created-at') }} :
            </v-col>

            <v-col>
              {{ invitation.createdAt ? formatDate(invitation.createdAt) : '-' }}
            </v-col>

            <v-col class="label" sm="3">
              {{ $t('member.last-sent-at') }} :
            </v-col>
            <v-col>
              {{ invitation.lastSentAt ? formatDate(invitation.lastSentAt) : '-' }}
            </v-col>
          </v-row>

          <!-- Groupes -->

          <headline class="mt-16">
            {{ $tc("member-group.title") }}
          </headline>

          <v-row>
            <v-col class="label" sm="3">
              {{ $tc('member-group.title', 2) }}
            </v-col>
            <v-col sm="3">
              <v-select
                  v-model="invitation.groups"
                  :items="groups"
                  item-text="name"
                  item-value="id"
                  hide-details
                  multiple
                  chips
                  clearable
              >
                <template #selection="{ item }">
                  <v-chip dark outlined :color="getOrganizationGroupTypeColor(item.type)">
                    {{ item.name }}
                  </v-chip>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <!-- Badges -->
          <template v-if="isDeveloper">
            <headline class="mt-16">
              {{ $tc("member.token", 2) }}
            </headline>

            <v-row>
              <v-col class="label" sm="3">
                {{ $tc("member.token", 1) }}
              </v-col>
              <v-col sm="3">
                <v-select
                    v-model="invitation.tokenId"
                    :items="organizationTokens"
                    item-text="name"
                    item-value="id"
                    hide-details
                    chips
                    clearable
                >
                  <template #item="{ item }">
                    <div v-if="item.name">{{ item.name }}</div>
                    <div v-else class="grey--text font-italic">{{ item.id }}</div>
                  </template>
                  <template #selection="{ item }">
                    {{ item.name ? item.name : item.id }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </template>

          <!-- Submit -->
          <v-row>
            <v-col class="justify-end">
              <v-btn
                  color="primary"
                  type="submit"
                  :disabled="!isFormValid"
                  class="mr-12"
              >
                {{ isAlreadySent ? $t('update') : $t('member.invitation.send') }}
              </v-btn>
            </v-col>
          </v-row>


        </v-form>
      </div>
    </div>
  </div>
</template>

<script>

import MemberInvitationRepository from "@repository/MemberInvitationRepository";
import CustomFields from "@blocks/CustomFields.vue";
import MemberTypeRepository from "@repository/MemberTypeRepository";
import MemberRepository from "@repository/MemberRepository";
import MemberGroupRepository from "@repository/MemberGroupRepository";
import TokenRepository from "@repository/TokenRepository";

export default {
  components: {
    CustomFields
  },

  data() {
    return {
      invitation: {
        id: null,
        memberType: {
          id: null,
        },
        tokenId: null,
        vehicleRegistrationNumber: null,
        externalReference: null,
        groups: [],
        customFields: [],
        lastSentAt: null,
      },
      memberTypes: [],
      groups: [],
      organizationTokens: [],
      customFields: [],
      isFormValid: false,
      typesLoaded: false,
    }
  },


  mounted() {
    if(!this.currentUser.permissions.includes('ORGANIZATION_READ')) {
      this.$router.replace({name: 'dashboard'})
      return
    }

    this.loadData()
  },

  computed: {
    invitationId() {
      return this.$route.params.invitationId
    },

    isAlreadySent() {
      return this.invitation.lastSentAt != null
    },

    invitationName() {
      if(this.invitation.firstname === null) {
        return this.invitation.lastname ?? ''
      }
      return this.invitation.lastname ? this.invitation.firstname + ' ' + this.invitation.lastname : this.invitation.firstname
    }
  },

  watch: {
    'invitation.memberType.id': {
      handler(val, oldVal) {
        if(oldVal) {
          this.invitation.customFields = []
        }
        this.loadCustomFields()
      },
      immediate: true
    },

    invitation: {
      handler() {
        this.checkForm()
      },
      deep: true,
    },
  },

  methods: {
    checkForm() {
      this.isFormValid = this.invitation?.firstname?.trim() && this.invitation?.lastname?.trim()
    },

    save() {
      if(!this.isFormValid) {
        return
      }
      this.showLoading(true)
      let data = this.cloneObj(this.invitation, ['customFields', 'externalReference', 'vehicleRegistrationNumber', 'lastname', 'firstname', 'groups', 'tokenId'])
      data.memberTypeId = this.invitation.memberType.id

      MemberInvitationRepository.update(this.invitation.id, data).then(() => {
        if(this.invitation.lastSentAt) {
          this.notifySuccess(this.$t('update-success'))
        } else {
          this.notifySuccess(this.$t('member.invitation.sent'))
        }
        this.loadData()
      }).catch(err => {
        this.showLoading(false)
        this.$dialog.notify.error(this.$t(err.response.data.message))
      })
    },

    loadData() {
      this.showLoading(true)
      MemberInvitationRepository.get(this.invitationId).then(data => {
        if(data.memberType === null) {
          data.memberType = {
            id: null,
          }
        }
        this.invitation = data
        this.checkForm()
        this.loadMemberTypes()
        this.loadGroups()
        this.loadTokens()
        this.showLoading(false)
      }).catch(err => {
        this.showLoading(false)

        this.$dialog.notify.error(this.$t(err.response.data.message))

        if(err.response.data.message === 'organization.member.invitation.errors.not-found') {
          this.$router.replace({name: 'organizations.members.invitations'})
        }
      })
    },

    loadMemberTypes() {
      let query = {
        organizationId: this.invitation.organization.id
      }
      MemberTypeRepository.search(query).then(response => {
        let data = response.items;
        this.memberTypes = []
        for(let i = 0; i < data.length; i++) {
          this.memberTypes.push({
            id: data[i].id,
            name: data[i].name,
            customFields: data[i].customFields
          })
        }

        this.loadCustomFields()

        this.typesLoaded = true
      })
    },

    loadGroups() {
      let data = {
        organizationId: this.invitation.organization.id
      }

      MemberGroupRepository.search(data).then(response => {
        this.groups = response.items
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
      })
    },

    loadTokens() {
      let data = {
        organizationId: this.invitation.organization.id,
        corporate: false,
        unassigned: true,
      }

      TokenRepository.search(data).then(response => {
        this.organizationTokens = response.items
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
      })
    },

    loadCustomFields() {
      const selectedType = this.memberTypes.find(type => type.id === this.invitation.memberType?.id);
      if (selectedType) {
        this.customFields = selectedType.customFields;
      }
    },

    resendInvitation() {
      const id = this.invitation.id

      this.showLoading(true)
      MemberInvitationRepository.resendMemberInvitation(id).then(() => {
        this.loadData()
        this.showLoading(false)
        this.$dialog.notify.success(this.$t('member.invitation.resend'))
      }).catch(e => {
        this.showLoading(false)
        this.$dialog.notify.error(e.response?.data.message ?? e.message)
      })
    },
  }
}
</script>

<style lang="scss">
.member-wrapper {

  .no-border-bottom {
    border-bottom: none !important;
  }
}
</style>
