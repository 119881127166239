<template>
  <div class="meter-device-list">

    <template v-if="withControl">
      <v-row v-if="currentUser.permissions.includes('OPERATOR_WRITE')">
        <v-col class="text-right">
          <v-btn small :to="{name: 'meter-device.add'}">
            <v-icon left>mdi-plus</v-icon>
            {{ $t('meter-device.add') }}
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <v-row>
      <v-col>
        <search-field
            :placeholder="$t('meter-device.search-placeholder')"
            :search.sync="search"
            clearable
        />
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-10">
      <v-col>
        <v-data-table
            class="cp-list"
            :headers="headers"
            :items="items"
            :loading="loading"
            :options.sync="options"
            :server-items-length="total"
        >

          <template #item.type="{item}">
            {{ $t(`enums.meter-device.${item.type}`) }}
          </template>
          <template #item.name="{item}">
            <router-link :to="{name: 'meter-device.detail', params: {id: item.id}}">
              <span class="identifier">{{ item.name }}</span>
            </router-link>
          </template>
          <template #item.customer="{item}">
            {{ item.customer?.firstname || ''}}  {{ item.customer?.lastname || ''}}
          </template>
          <template #item.options="{item}" v-if="isDeveloper">
            <v-btn icon @click="deleteMeterDevice(item)">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>

        </v-data-table>
      </v-col>
    </v-row>


  </div>
</template>

<script>


import MeterDeviceRepository from "@repository/MeterDeviceRepository";

export default {
  components: {},
  data() {
    return {
      loading: false,
      search: null,
      items: [],
      total: 0,
      options: null,

    }
  },

  props: {
    organizationId: String,
    locationId: String,
    withControl: Boolean,
    loaded: Boolean,
    count: Number,
  },

  mounted() {
    if (!this.currentUser.permissions.includes('OPERATOR_READ')) {
      this.$router.replace({name: 'dashboard'})
      return
    }

    this.$emit('update:count', 0)
    this.$emit('update:loaded', false)
  },

  watch: {
    search(val) {
      if (this.options.page > 1) {
        this.options.page = 1
      } else {
        this.searchData()
      }
    },

    options(val) {
      this.searchData()
    }
  },

  computed: {
    headers() {
      return [
        {text: this.$t('organization.organization'), value: 'organization.name'},
        {text: this.$t('name'), value: 'name'},
        {text: this.$t('meter-device.type'), value: 'type'},
        {text: this.$tc('customer.customer', 1), value: 'customer'},
        {text: '', value: 'options', sortable: false, align: 'right'},
      ]
    },

  },

  methods: {
    searchData() {

      let options = this.options


      let query = this.search;
      let offset = options.itemsPerPage * options.page - options.itemsPerPage;
      let limit = options.itemsPerPage;
      let orderBy = options.sortBy.length > 0 ? options.sortBy[0] : 'name'
      let orderDirection = options.sortDesc.length > 0 && options.sortDesc[0] ? 'desc' : 'asc'


      const data = {
        query: query,
        offset: offset,
        limit: limit,
        orderBy: orderBy,
        orderDirection: orderDirection,
      }
      if (this.organizationId) {
        data.organizationId = this.organizationId
      }
      if (this.locationId) {
        data.locationId = this.locationId
      }

      this.loading = true
      MeterDeviceRepository.search(data).then(result => {
        this.total = result.total
        this.items = result.items
        this.loading = false
        this.$emit('update:count', this.total)
        this.$emit('update:loaded', true)
      }).catch(err => {
        this.$dialog.notify.error(this.$t(err.response?.data?.message || err.message))
        this.loading = false
      })
    },
    deleteMeterDevice(item) {
      let id = item.id;
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          MeterDeviceRepository.delete(id).then(response => {
            this.$dialog.notify.success(this.$t('update-success'))
            this.showLoading(false)
            this.searchData();
          }).catch(e => {
            this.showLoading(false)
            this.$dialog.notify.error(e.response.data.message)
          })
        }
      })
    },


  }
}
</script>

<style lang="scss">
.meter-device-list {

}


</style>
