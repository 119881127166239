import Vue from 'vue'

class MemberGroupAssociationRepository {

    get(id) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/member-group/association/get/' + id,
                method: 'get',
            })
                .then(response => {
                    // console.log(response)
                    resolve(response.data)
                }).catch(err => {
                reject(err)
            })
        })
    }

    create(data) {
        //format du multi select members pour
        let listAssociations

        if(data.membersIds) {
            listAssociations = data.membersIds
                .filter(memberId => memberId !== null)
                .map(memberId => {
                    return {
                        "memberId": memberId,
                        "memberGroupId": data.memberGroupId
                    };
                });
        } else {
            listAssociations = data.groupsIds
                .filter(memberGroupId => memberGroupId !== null)
                .map(memberGroupId => {
                    return {
                        "memberId": data.memberId,
                        "memberGroupId": memberGroupId
                    };
                });
        }

        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/member-group/association/create',
                method: 'post',
                data: {
                    associations: listAssociations,
                },
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    update(id, data) {

    }

    search(data) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/member-group/association/search',
                method: 'post',
                data,
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    delete(id) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/member-group/association/delete/' + id,
                method: 'delete',
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    types() {

    }
}

export default new MemberGroupAssociationRepository()