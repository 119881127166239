<template>
  <div class="member-types">

    <v-breadcrumbs
        class="level-2 pt-0"
        :items="[
            {text: $tc('member.member', 2), to: {name: 'organizations.members'}, exact: true},
            {text: $t('member-type.manage') },
        ]"
        divider=">"
    ></v-breadcrumbs>

    <!-- Add button -->
    <v-row>
      <v-col class="text-right">
        <v-btn small :to="{ name: 'organizations.members.types.add' }">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('member-type.add') }}
        </v-btn>
        <settings-button />
      </v-col>
    </v-row>

    <!-- Search -->
    <v-row>
      <v-col>
        <search-field
            :placeholder="$t('member-type.search')"
            :search.sync="search"
            :options.sync="options"
            clearable
        >
        </search-field>
      </v-col>
    </v-row>

    <!-- List -->
    <v-row no-gutters class="mt-10">
      <v-col>
        <v-data-table
            class="member-list"
            :headers="headers"
            :items="items"
            :loading="loading"
            :options.sync="options"
            :server-items-length="total"
        >
          <template #item.name="{item}">
            <router-link :to="{name: 'organizations.members.types.edit', params: {memberTypeId: item.id}}">
              {{ item.name }}
            </router-link>
          </template>
          <template #item.options="{item}">
            <v-row>
              <v-col class="text-center">
                 <v-btn icon :to="{name: 'organizations.members.types.edit', params: {memberTypeId: item.id}}">
                   <v-icon>mdi-pencil</v-icon>
                 </v-btn>
                 <v-btn class="ml-3" icon @click="deleteType(item.id)">
                   <v-icon>mdi-delete</v-icon>
                 </v-btn>
              </v-col>
            </v-row>
          </template>

        </v-data-table>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import OrganizationRepository from "@repository/OrganizationRepository"
import MemberTypeRepository from "@repository/MemberTypeRepository"
import SettingsButton from "@pages/Organization/Detail/Member/elements/SettingsButton.vue";

export default {
  components: {SettingsButton},
  data() {
    return {
      search: '',
      loading: false,
      selectedItem: null,
      items: [],
      options: null,
      total: 0,
      headers: [
        {text: this.$t('name'), value: 'name'},
        {text: '', value: "options", width: 200},
      ],
    }
  },

  mounted() {

  },

  watch: {
    search() {
      this.loadData('search')
    },

    options() {
      this.loadData('options')
    },

    selectedItem(val) {
      this.$router.push({name: 'organizations.members.types.edit', params: {memberTypeId: val.id} })
    },
  },

  computed: {
    organization() {
      return this.$parent.organization ?? null
    }
  },

  methods: {

    loadData(source) {
      this.loading = true

      let data = {
        searchText: this.search,
      }
      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage

      data.organizationId = this.organization.id

      if (this.options.sortBy.length > 0) {
        data.orderBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.orderDirection = this.options.sortDesc[0] ? 'desc' : 'asc'
      }

      MemberTypeRepository.search(data).then(response => {
        this.total = response.total
        this.items = response.items
        this.loading = false
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
        this.loading = false
      })
    },

    deleteType(id) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.loading = true
          MemberTypeRepository.delete(id).then(() => {
            this.loadData()
          }).catch(e => {
            this.$dialog.notify.error(e.response?.data?.message || e.message)
            this.loading = false
          })
        }
      })
    }
  },
}
</script>

<style lang="scss">
.member-types {

}
</style>
