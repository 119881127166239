<template>
  <div>
    <v-autocomplete
        ref="input"
        v-model="data"
        :search-input.sync="search"
        :label="label"
        :items="itemFiltered"
        :loading="loading"
        v-on="$listeners"
        :rules="rules"
        item-value="id"
        :solo="solo"
        chips
        :deletable-chips="multiple"
        :clearable="clearable"
        :append-icon="appendIcon"
        :readonly="readonly"
        :disabled="disabled"
        :placeholder="placeholder"
        no-filter
        :multiple="multiple"
        hide-details
        hide-no-data

    >
      <template v-slot:prepend-item v-if="multiple">
        <v-checkbox
            class="ml-4 mb-4 text-body-2"
            v-model="isSelectAllMeterDevices"
            @click="toggleSelectAllMeterDevices"
            :indeterminate="isIndeterminateMeterDevice"
            hide-details
            ripple
        >
          <template v-slot:label>
            <span class="text-body-2 ml-6">{{ $t('select-all') }}</span>
          </template>
        </v-checkbox>
        <v-divider />
      </template>

      <template #item="{item, attrs, on}">
        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
          <v-list-item-action  v-if="multiple">
            <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-subtitle>
              {{  $t('enums.meter-device.' + item.type) }} - {{ item.location.address.city }} <v-chip color="primary" class="ml-2" x-small v-if="item.customer">{{ $t('customer.home-charging-point')}}</v-chip>
            </v-list-item-subtitle>
            <v-list-item-title>
              <v-row no-gutters align="center">
                {{ item.name }}
              </v-row>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import MeterDeviceRepository from "@repository/MeterDeviceRepository";

export default {
  data() {
    return {
      loading: false,
      search: null,
      data: this.value,
      items: [],
      isSelectAllMeterDevices: false,
    }
  },

  model: {
    prop: 'value',
  },

  props: {
    value: [String, Number, Array],
    limit: {type: Number, default: 20},
    rules: Array,
    multiple: Boolean,
    placeholder: String,
    label: String,
    disabled: Boolean,
    readonly: Boolean,
    appendIcon: String,
    solo: Boolean,
    clearable: Boolean,
    exclude: Array,
    filterQuery: Object,
  },

  mounted() {
    this.loadData()
  },

  destroyed() {

  },

  watch: {
    data(val) {
      this.$emit('input', val)
    },

    value(val) {
      this.data = val
    },

    search(val) {
      this.loadData()
    }
  },

  computed: {
    itemFiltered() {
      return this.items.filter(item => {
        if(this.exclude) {
          return !this.exclude.includes(item.id)
        } else {
          return true
        }
      }).map(item => ({...item, text: `${item.name}`}))
    },

    isIndeterminateMeterDevice() {
      return this.data.length > 0 && this.data.length < this.itemFiltered.length
    }

  },


  methods: {
    loadData() {

      let query = this.search

      if(!this.search && this.data) {
        if(typeof this.data === 'string') {
          query = this.data
        }
      }

      this.loading = true
      MeterDeviceRepository.search({query, limit: this.limit, ...this.filterQuery}).then(data => {
        this.items = data.items
        this.loading = false
      }).catch(error => {
        this.$dialog.notify.error(error.response?.data?.message ?? error.message)
      })
    },

    focus() {
      this.$refs.input.focus()
    },

    toggleSelectAllMeterDevices() {
      if(this.isSelectAllMeterDevices) {
        this.data = this.itemFiltered.map(item => item.id)
      } else {
        this.data = []
      }
    }

  }
}
</script>

<style lang="scss">


</style>
