<template>
  <div class="organization-view  pa-10 pt-0" v-if="organization">

    <tab-navigation
        :title="$t('organization.organization')"
        icon="ico-organization-menu"
        :breadcrumbs="[
            {text: $t('organization.organizations'), to: {name: 'organizations.search'}, exact: true},
            {text: organization.name},
        ]"
    >
      <v-tabs>
        <v-tab :to="{name: 'organizations.detail'}" exact>{{ $t('information') }}</v-tab>
        <v-tab v-if="false" :to="{name: 'organizations.children'}">{{ $tc('organization.sub-organization', 2) }}</v-tab>
        <v-tab :to="{name: 'organizations.charging-points'}">{{ $t('charging-point.charging-points')}}</v-tab>
        <v-tab :to="{name: 'organizations.customers'}">{{ $tc('customer.customer', 2)}}</v-tab>
        <v-tab v-if="isSpecialUser || currentUser.isZE" :to="{name: 'organizations.members'}">{{ $tc('member.member', 2)}}</v-tab>
        <v-tab :to="{name: 'organizations.usages'}">{{ $t('charging-point.usage') }}</v-tab>
        <v-tab :to="{name: 'organizations.tokens'}">{{ $tc('rfid.rfid', 2) }}</v-tab>
        <v-tab v-if="(isSpecialUser || currentUser.isZE) && tabs.includes('expense')" :to="{name: 'organizations.expense-periods'}">{{ $t('expense-refund.expense-refunds')}}</v-tab>
        <v-tab v-if="currentUser.isZE && tabs.includes('energy-provider')" :to="{name: 'organizations.energy-provider'}">{{ $t('recharge-service.recharge-service')}}</v-tab>
        <v-tab :to="{name: 'organizations.tariffs'}">{{ $t('organization.pricing') }}</v-tab>
        <v-tab v-if="currentUser.isZE" :to="{name: 'organizations.subscriptions'}">{{ $tc('subscription.subscription', 2) }}</v-tab>
        <v-tab :to="{name: 'organizations.users'}">{{ $t('user.users') }}</v-tab>
      </v-tabs>
    </tab-navigation>



    <keep-alive :key="$route.fullPath" :include="cachedPages">
      <router-view></router-view>
    </keep-alive>

  </div>
</template>

<script>
import OrganizationRepository from "@/repository/OrganizationRepository"
import TabNavigation from "@blocks/TabNavigation.vue";

export default {
  name: 'OrganizationDetail',
  components: {TabNavigation},
  data() {
    return {
      organization: null,
      tabs: [],
    }
  },

  mounted() {
    this.loadData()
    this.loadTabs()
  },

  watch: {
    id() {
      this.loadData()
    }
  },

  computed: {

    id() {
      return this.$route.params.id
    },

    cachedPages() {
      let pages = []


      if (this.$route.fullPath.includes('organizations')) {
        // pages.push('OrganizationMembers')
        // pages.push('MemberGroupSearch')
        // pages.push('MemberTypeSearch')
      }

      return pages
    },

    // convert string to camelcase


    /**
     * Returns an array of breadcrumb items.
     *
     * @return {Array} The array of breadcrumb items.
     */
    breadcrumbs() {
      if(!this.organization?.path) {
        return []
      }

      let items = []
      this.organization.path.split('/').forEach((p,i) => {
        items.push({
          text: p,
          to: {params: {id: this.organization.hierarchies[i]}}
        })
      })



      return items
    }
  },

  methods: {
    loadData() {
      return new Promise((resolve, reject) => {
        if(this.id) {
          this.showLoading(true)
          OrganizationRepository.get(this.id).then(organization => {
            this.organization = organization
            this.showLoading(false)
            resolve(organization)
          }).catch(err => {
            this.$dialog.notify.error(err.response.data?.message || err.message)
            this.showLoading(false)
            reject(err)
          })
        }
      })

    },

    loadTabs() {
      if(this.id) {
        OrganizationRepository.tabs(this.id).then(tabs => {
          this.tabs = tabs
        }).catch(err => {
          this.$dialog.notify.error(err.response.data?.message || err.message)
        })
      }
    },
  },

}
</script>

<style lang="scss">


</style>
