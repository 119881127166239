<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card>
      <v-card-title>
        <h3>{{ $t('expense-refund.send-email') }}</h3>
      </v-card-title>

      <v-divider class="mb-6"/>

      <v-card-text>

        <v-row dense>
          <v-col>
            {{ $t('type') }}
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-checkbox v-model="form.types" value="global" label="ZEborne" hide-details />
          </v-col>
          <v-col>
            <v-checkbox v-model="form.types" value="organization" :label="$t('organization.organization')" hide-details />
          </v-col>
          <v-col>
            <v-checkbox v-model="form.types" value="members" :label="$tc('member.member', 2)" hide-details />
          </v-col>
        </v-row>

        <transition name="fade">
          <div v-if="form.types.includes('members')">
            <v-divider class="my-6"></v-divider>
            <v-row dense>
              <v-col>
                <v-autocomplete
                    :label="$tc('member.member', 2)"
                    v-model="form.members"
                    :items="membersCleaned"
                    item-text="fullName"
                    item-value="id"
                    hide-details
                    :placeholder="$t('expense-refund.search-member-placeholder')"
                    multiple
                >
                  <template #item="{item}">
                    <v-list-item class="d-flex" @click="sendMailMemberToggleItem(item)">
                      <div>
                        <v-simple-checkbox color="primary" :value="sendMailMemberIsSelected(item)" @click.stop="sendMailMemberToggleItem(item)" />
                      </div>
                      <div class="ml-2">{{ item.fullName }}</div>
                    </v-list-item>

                  </template>

                  <template #selection="{item, index, attrs}">
                    <v-chip
                        v-if="form.members.length < 6"
                        v-bind="attrs"
                        small
                    >
                      {{ item.fullName }}
                    </v-chip>
                    <span class="mr-3" v-else-if="index === 0">
                      {{ $tc('expense-refund.selected-members', form.members.length, { count: form.members.length }) }}
                    </span>

                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="text-right">
                <v-btn text x-small @click="sendMailSelectAllMembers" :disabled="!canSelectAll">{{ $t('select-all') }}</v-btn>
                <v-btn text x-small @click="sendMailUnselectAllMembers" :disabled="!canUnselectAll">{{ $t('unselect-all') }}</v-btn>
              </v-col>
            </v-row>
          </div>
        </transition>

        <v-divider class="my-6"></v-divider>

        <v-row>
          <v-col>
            <v-checkbox
                label="Mail de test"
                v-model="form.isTestMail"
                persistent-hint
                :hint="$t('expense-refund.send-email-test-hint')"

            />
          </v-col>
        </v-row>


        <v-row v-if="!form.isTestMail">
          <v-col>
            <v-alert type="warning">
              {{ $t('expense-refund.send-not-test-email-warning') }}
            </v-alert>
          </v-col>
        </v-row>

      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-row class="ma-0">
          <v-col class="text-right">
            <v-btn small @click="close">
              {{ $t('close') }}
            </v-btn>
            <v-btn color="primary" class="ml-3" small @click="sendMail">
              {{ $t('expense-refund.send-email') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ExpenseRefundRepository from "@repository/ExpenseRefundRepository";

export default {
  data() {
    return {
      dialog: false,
      form: {
        isTestMail: true,
        types: ['global', 'members', 'organization'],
        members: [],
      }
    }
  },

  props: {
    periodId: String,
    members: Array,
  },

  watch: {
    dialog(val) {
      if(val) {
        this.form.periodId = this.periodId
        this.form.types = ['global', 'members', 'organization']
        this.form.isTestMail = true
        this.sendMailSelectAllMembers()
      }
    },

    'form.types'(val) {
      if(val.includes('members')) {
        this.sendMailSelectAllMembers()
      } else {
        this.sendMailUnselectAllMembers()
      }
    },
  },

  computed: {

    membersCleaned() {
      const members = this.members.map(item => {
        return {
          ...item,
          fullName: `${item.firstname} ${item.lastname}`
        }
      })
      members.sort((a, b) => a.lastname.localeCompare(b.lastname))

      return members
    },

    canSelectAll() {
      return this.form.members.length !== this.members.length
    },

    canUnselectAll() {
      return this.form.members.length > 0
    },
  },

  methods: {
    open() {
      this.dialog = true
    },

    close() {
      this.dialog = false
    },

    sendMailSelectAllMembers() {
      this.form.members = this.members.map(item => item.id)
    },

    sendMailUnselectAllMembers() {
      this.form.members = []
    },

    sendMailMemberIsSelected(item) {
      return this.form.members.includes(item.id);
    },

    sendMailMemberToggleItem(item) {
      if (this.form.members.includes(item.id)) {
        this.form.members = this.form.members.filter(v => v !== item.id);
      } else {
        this.form.members.push(item.id);
      }
    },

    sendMail() {
      this.showLoading(true)
      ExpenseRefundRepository.sendMail(this.periodId, this.form).then(() => {
        this.showLoading(false)
        this.close()
      }).catch(e => {
        this.notifyError(e)
        this.showLoading(false)
      })
    },
  }
}
</script>


<style lang="scss">

</style>