<template>
  <div class="member-search mt-5">

    <v-breadcrumbs
        class="level-2"
        :items="[
            { text: 'Service de recharge', to: {name: 'organizations.energy-provider' }, exact: true},
            { text: periodName },
        ]"
        divider=">"
    ></v-breadcrumbs>

    <v-row dense>
      <v-col class="text-right">
        <v-btn small @click="syncPeriod" v-if="period && !period?.billed">
          <v-icon left>mdi-sync</v-icon>
          Sync CDR
        </v-btn>
      </v-col>
    </v-row>


    <v-row class="mt-10" v-if="period">
      <v-col cols="6">
        <v-card>
          <v-card-text>
            <v-row dense>
              <v-col cols="4">
                {{ $t('expense-refund.period') }}
              </v-col>
              <v-col cols="8">
                {{ periodName }}
              </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>

            <v-row dense>
              <v-col cols="4">
                {{ $t('expense-refund.charge-count') }}
              </v-col>
              <v-col cols="8">
                {{ period.nbLines }}
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card>
          <v-card-text>

            <v-row dense>
              <v-col cols="4">
                {{ $t('charging-point.consumed-energy') }}
              </v-col>
              <v-col cols="8">
                {{ numberFormat(period.energy/1000, 3) }} kWh
              </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>

            <v-row dense>
              <v-col cols="4">
                <b>{{ $t('amount') }}</b>
              </v-col>
              <v-col cols="8">
                <b>{{ numberFormat(period.amount) }} €</b>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>



    <!-- List -->
    <v-row no-gutters class="mt-10">
      <v-col>
        <v-data-table
            :headers="headers"
            :items="items"
            :options="options"
            :loading="loading"
        >

          <template #item.billableItem.name="{item}">
            <router-link :to="{name: 'organizations.energy-provider.period.lines', params: {periodId: periodId}, query: {bId: item.billableItem.id} }">
              {{ item.billableItem.name }}
            </router-link>
          </template>


          <template #item.duration="{value}">
            {{ formatDuration(value) }}
          </template>

          <template #item.energy="{value}">
            {{ numberFormat(value/ 1000, 3) }} kWh
          </template>

          <template #item.amount="{value}">
            {{ numberFormat(value) }} €
          </template>

          <template #item.options="{item}">

            <router-link :to="{name: 'organizations.energy-provider.period.lines', params: {periodId: periodId}, query: {bId: item.billableItem.id} }">
              <v-icon>mdi-arrow-right-thick</v-icon>
            </router-link>
          </template>


        </v-data-table>

      </v-col>
    </v-row>







  </div>
</template>

<script>
import EnergyProviderRepository from "@repository/EnergyProviderRepository";

export default {
  data() {
    return {
      loading: false,
      period: null,
      items: [],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['stopTime'],
        sortDesc: [false],
      },
    }
  },

  mounted() {
    this.loadData()
  },

  watch: {


  },

  computed: {

    headers() {
      return [
        {text: this.$t('name'), value: 'billableItem.name'},
        {text: this.$t('count'), value: 'nbLines'},
        {text: this.$t('energy'), value: 'energy'},
        {text: this.$t('duration'), value: 'duration'},
        {text: this.$t('net'), value: 'amount'},
        { value: 'options'},
      ]
    },

    organization() {
      return this.$parent.organization ?? null
    },


    periodId() {
      return this.$route.params.periodId
    },

    periodName() {
      if(this.period?.startDate) {
        return this.formatUtcDate(this.period.startDate) + ' > ' + this.formatUtcDate(this.period.endDate)
      }
      return null
    },

  },

  methods: {

    loadData() {
      return new Promise((resolve, reject) => {
        this.showLoading(true)


        EnergyProviderRepository.period(this.periodId).then(response => {
          this.period = response.period
          this.items = response.items
          this.showLoading(false)
          resolve(response)
        }).catch(err => {
          this.notifyError(err)
          this.loading = false
          reject(err)
        })
      })
    },


    syncPeriod() {
      this.showLoading(true)
      EnergyProviderRepository.syncPeriod(this.periodId).then(() => {
        this.loadData().then(() => {
          this.notifySuccess(this.$t('sync-success'))
        })
      }).catch(err => {
        this.showLoading(false)
        this.notifyError(err)
      })
    },

    exportPdf() {
      this.showLoading(true)
      EnergyProviderRepository.exportPeriodPdf(this.periodId).then(() => {
        this.showLoading(false)
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
        this.showLoading(false)
      })
    },


  },
}
</script>

<style lang="scss">
.member-search {
  min-height: 1000px;
  .member-list {
    td, th {
      cursor: pointer;
      &.text-start {
        text-align: left !important;
      }
    }
  }
}
</style>
