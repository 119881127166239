<template>
  <div class="mb-5">
    <v-dialog width="70%" v-model="dialog">
      <v-card>
        <v-form @submit.prevent="addMemberInGroup">
          <v-card-title>
            <span v-if="memberGroup">{{ $t('member-group.select-members') }}</span>
            <span v-else>{{ $t('member-group.select-groups') }}</span>
          </v-card-title>
          <v-card-text>

            <!-- Select des groupes ou membres -->
            <v-row class="my-5">
              <v-col class="text-right center">

                <!-- Members -->
                <v-select
                    v-if="memberGroup"
                    v-model="form.membersIds"
                    :items="membersOrganization"
                    item-text="name"
                    item-value="id"
                    chips
                    multiple
                ></v-select>

                <!-- Groups -->
                <v-select
                    v-else
                    v-model="form.groupsIds"
                    :items="groupsOrganization"
                    item-text="name"
                    item-value="id"
                    chips
                    multiple
                >
                  <template v-slot:selection="{ item }">
                    <member-group-type :item="item"></member-group-type>
                  </template>
                </v-select>

              </v-col>
            </v-row>

          </v-card-text>
          <v-card-actions>
            <v-row class="ma-0">
              <v-col>
                <v-btn small class="mt-2" color="primary" type="submit">
                  <span v-if="memberGroup && memberGroup !== ''">{{ $t('member.add') }}</span>
                  <span v-else>{{ $t('member-group.add') }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- END dialog window -->

    <!-- Add button -->
    <div class="text-right mb-5 mt-2">
      <v-btn small @click="open()" v-if="!loading">
        <v-icon left>mdi-account-group</v-icon>
        <span v-if="memberGroup && memberGroup !== ''">{{ $t('member.add') }}</span>
        <span v-else>{{ $t('member-group.add') }}</span>
      </v-btn>
    </div>

    <!-- List -->
    <v-data-table
        :headers="headers"
        :items="associations"
        :loading="loading"
        hide-default-footer
        disable-pagination
        width="70%"
    >
      <template #item.groupType="{ item }">
        <member-group-type :item="item.group"></member-group-type>
      </template>
      <template #item.options="{ item }">
        <v-btn class="ml-3" icon @click="deleteMemberInGroup(item.id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>

    </v-data-table>
  </div>
</template>

<script>

import MemberGroupAssociationRepository from "@repository/MemberGroupAssociationRepository";
import MemberRepository from "@repository/MemberRepository";
import MemberGroupRepository from "@repository/MemberGroupRepository";
import MemberGroupType from "@blocks/MemberGroupType.vue";

export default {
  components: {
    MemberGroupType
  },

  data() {
    return {
      dialog: false,
      searchMember: null,
      membersOrganization: [],
      groupsOrganization: [],
      form: {
        membersIds: null,
        groupsIds: null,
        memberGroupId: null,
        memberId: null,
      },
      membersInGroup: [],
      groupsInMember: [],
      loading: false,
    };
  },

  props: {
    memberGroup: {type: String, default: null}, //ID
    member: {type: String, default: null}, //ID
    organizationId: {type: String},
  },


  mounted() {
    this.loadData()
  },

  watch: {
    memberGroup: function () {
      // charge la liste des members déjà associé au memberGroup
      this.loadMembersInGroup()
    },

    member: function (val) {
      // charge la liste des members déjà associé au memberGroup
      this.loadGroupsInMember()
    },
  },

  computed: {
    headers() {
      if (this.memberGroup !== null) {
        return [
          {text: this.$tc('name'), value: 'member.lastname'},
          {text: this.$tc('firstname'), value: 'member.firstname'},
          {text: '', value: 'options', width: 140},
        ]
      } else {
        return [
          {text: this.$tc('name'), value: 'group.name'},
          {text: this.$tc('type'), value: 'groupType'},
          {text: '', value: 'options', width: 140},
        ]
      }
    },

    associations() {
      return (this.memberGroup !== null) ? this.membersInGroup : this.groupsInMember
    }
  },

  methods: {
    open() {
      this.dialog = true
      if (this.memberGroup !== null) {
        this.loadMembersOrganization()
      } else {
        this.loadGroups()
      }
    },

    close() {
      this.dialog = false
    },

    loadData() {
      if (this.memberGroup !== null) {
        this.loadMembersInGroup()
      } else {
        this.loadGroupsInMember()
      }
    },


    addMemberInGroup() {
      if (this.memberGroup === null && this.member === null) {
        return
      }

      if (this.memberGroup !== null) { // On travaille sur les membres d'un groupe
        if (this.form.membersIds === null) {
          this.close()
          return
        }
        this.form.memberGroupId = this.memberGroup
      } else if (this.member !== null) { // On travaille sur les groupes d'un membre
        if (this.form.groupsIds === null) {
          this.close()
          return
        }
        this.form.memberId = this.member;
      }
      this.showLoading(true)
      MemberGroupAssociationRepository.create(this.form).then(result => {
        if (result) {
          this.$dialog.notify.success(this.$t('update-success'))
        } else {
          this.$dialog.notify.error(this.$t('error'))
        }
        this.loadingSearchCustomer = false
        this.showLoading(false)
        this.dialog = false
        this.loadData();
      }).catch(e => {
        this.showLoading(false)
        this.$dialog.notify.error(e.response.data.message)
      })
    },

    deleteMemberInGroup(memberGroupId) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          MemberGroupAssociationRepository.delete(memberGroupId).then(response => {
            this.$dialog.notify.success(this.$t('update-success'))
            this.showLoading(false)
            this.loadData();
          }).catch(e => {
            this.showLoading(false)
            this.$dialog.notify.error(e.response.data.message)
          })
        }
      })
    },

    loadMembersInGroup() {
      this.loading = true
      let query = {
        memberGroupId: this.memberGroup
      }
      MemberGroupAssociationRepository.search(query).then(data => {
        this.membersInGroup = data
        this.loading = false
      })
    },

    loadGroupsInMember() {
      this.loading = true
      let query = {
        memberId: this.member
      }
      MemberGroupAssociationRepository.search(query).then(data => {
        this.groupsInMember = data
        this.loading = false
      })
    },

    loadMembersOrganization() {
      let query = {
        organizationId: this.organizationId
      }

      MemberRepository.search(query).then(data => {
        this.membersOrganization = []
        if (data.items === null) {
          return 'no result'
        }

        let members = data.items
        for (let i = 0; i < members.length; i++) {
          if (!this.membersInGroup.map(entry => entry.member.customer.id).includes(members[i].customer.id)) {
            this.membersOrganization.push({
              id: members[i].id,
              name: members[i].firstname + ' ' + members[i].lastname,
            })
          }
        }
      })
    },

    loadGroups() {
      let query = {
        organizationId: this.organizationId
      }

      MemberGroupRepository.search(query).then(data => {
        this.groupsOrganization = []
        if (!data) {
          console.log("no groups found")
          return 'no groups found'
        }

        let groups = data.items
        for (let i = 0; i < groups.length; i++) {
          if (!this.groupsInMember.map(entry => entry.group.id).includes(groups[i].id)) {
            this.groupsOrganization.push({
              id: groups[i].id,
              name: groups[i].name,
              type: groups[i].type,
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">

</style>
