<template>
  <div>
    <v-dialog width="80%" v-model="dialog" scrollable>
      <v-card class="cpo-private-accesses-modal">
        <v-card-title>
          {{ $t('organization.cpo-private-access.manage') }} : {{ organization.name }}
        </v-card-title>


        <v-divider />

        <v-card-text>

          <v-form @submit.prevent="searchAndAddCustomer">
            <v-row class="my-5 ">
              <v-col class="text-right center">
                <search-field
                    solo
                    :search.sync="searchCustomer"
                    :placeholder="$t('organization.cpo-private-access.add-customer')"
                    prepend-inner-icon="ico-magnify"
                >
                  <template #item="{item}">
                    <v-row class="item">
                      <v-col class="name">
                        {{ item.firstname }} {{ item.lastname }} {{ item.email }}
                      </v-col>
                    </v-row>
                  </template>

                  <template #selection="{item}">
                    {{ item.firstname }} {{ item.lastname }} {{ item.email }}
                  </template>
                </search-field>
              </v-col>
              <v-col class="flex-grow-0">
                <v-btn small class="mt-2" color="primary" type="submit" :disabled="!Validator.email(searchCustomer)">
                  Ajouter
                </v-btn>
              </v-col>
            </v-row>
          </v-form>


          <v-data-table
            :headers="headers"
            :items="cpoPrivateAccesses"
            disable-pagination
            hide-default-footer
          >
            <template #item.options="{item}">
              <v-btn icon @click="deleteCpoPrivateAccess(item)">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>

        </v-card-text>


        <v-divider></v-divider>

        <v-card-actions>

          <v-row class="ma-0">
            <v-col class="text-right">
              <v-btn color="primary" @click="dialog=false">
                {{ $t('close')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import OrganizationRepository from "@repository/OrganizationRepository";
import {EventBus} from "@includes/EventBus";
import CustomerRepository from "@repository/CustomerRepository";
import Validator from "@/service/Validator";

export default {

  components: {

  },

  data() {
    return {
      dialog: false,
      cpoPrivateAccesses: [],
      headers: [
        {text: this.$t('firstname'), value: 'firstname'},
        {text: this.$t('lastname'), value: 'lastname'},
        {text: this.$t('email'), value: 'email'},
        {text: this.$t('phone'), value: 'phone'},
        {text: '', value: 'options'},
      ],
      searchCustomer: null,
      loadingSearchCustomer: false,
      selectedCustomer: null,
      total: 0,
      customers: [],
    };
  },

  props: {
    organization: Object,
  },

  mounted() {

  },

  destroyed() {

  },


  watch: {

  },

  computed: {
    Validator() {
      return Validator
    }

  },

  methods: {
    open() {
      this.searchCustomer = null
      this.loadData()
      this.dialog = true
    },

    loadData() {
      OrganizationRepository.cpoPrivateAccesses(this.organization.id).then(accesses => {
        this.cpoPrivateAccesses = accesses;
      })
    },

    searchAndAddCustomer() {
      this.loadingSearchCustomer = true
      CustomerRepository.getByIdentifier(this.searchCustomer).then(result => {
        if(result) {
          this.addCustomer(result)
          this.searchCustomer = null
          this.$dialog.notify.success(this.$t('update-success'))
        } else {
          this.$dialog.notify.error(this.$t('organization.cpo-private-access.customer-not-found'))
        }
        this.loadingSearchCustomer = false
      })
    },



    addCustomer(customer) {
      if(customer) {
        this.showLoading(true)
        OrganizationRepository.addPrivateAccess(this.organization.id, customer.id).then(() => {
          this.loadData()
          this.showLoading(false)
        }).catch(e => {
          this.showLoading(false)
          this.$dialog.notify.error(e.response?.data.message ?? e.message)
        })

        this.$nextTick(() => {
          this.selectedCustomer = null
        })
      }
    },

    deleteCpoPrivateAccess(item) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          OrganizationRepository.deletePrivateAccess(item.id).then(() => {
            this.loadData()
            this.showLoading(false)
          }).catch(e => {
            this.showLoading(false)
            this.$dialog.notify.error(e.response?.data.message ?? e.message)
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .cpo-private-accesses-modal {

  }
</style>
