var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"customer-detail"},[_c('v-breadcrumbs',{staticClass:"level-2",attrs:{"items":[
          {text: _vm.$tc('member.member', 2), to: {name: 'organizations.members'}, exact: true},
          {text: _vm.$t('member-group.manage'), to: {name: 'organizations.members.groups'}, exact: true },
          {text: _vm.$t('member-group.' + (_vm.edit ? 'edit' : 'add')) },
      ],"divider":">"}}),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('headline',[_vm._v(" "+_vm._s(_vm.$tc("member-group.title"))+" ")]),_c('v-row',[_c('v-col',[_c('form-field',{attrs:{"label":_vm.$t('name'),"col-field":"9"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1),_c('v-row',[_c('v-col',[_c('form-field',{attrs:{"label":_vm.$t('type'),"type":"select","items":_vm.types,"col-field":"9","disabled":_vm.edit},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1)],1),(_vm.form.customFields)?[_c('headline',{staticClass:"mt-10"},[_vm._v(" "+_vm._s(_vm.$tc("custom-field.custom-field", 2))+" ")]),_c('v-row',[_c('v-col',[_c('custom-fields',{attrs:{"fields":_vm.form.customFieldsDefinition,"filter-query":{
                organizationId: _vm.organization.id,
                isCpoPrivate: true,
              },"col-field":9},model:{value:(_vm.form.customFields),callback:function ($$v) {_vm.$set(_vm.form, "customFields", $$v)},expression:"form.customFields"}})],1)],1)]:_vm._e(),_c('v-row',{staticClass:"mt-10 mb-4"},[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.edit ? 'update' : 'create'))+" ")])],1)],1)],2),(_vm.form?.id)?[_c('headline',{staticClass:"mt-10"},[_vm._v(" "+_vm._s(_vm.$t('member-group.members'))+" ")]),_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('members-groups-associations',{attrs:{"memberGroup":_vm.form.id,"organization-id":_vm.organization.id,"from":"memberGroupForm"}})],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }