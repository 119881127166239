<template>
  <div class="charging-point-search">


    <charging-point-list
        :organization-id="organization.id" />


    <div class="text-center" v-if="!meterDevicesLoaded">
      <v-progress-circular
          color="primary"
          size="80"
          indeterminate
      />
    </div>
    <div class="mt-16" v-show="meterDevicesCount > 0">
      <headline>{{ $tc('meter-device.meter-device', 2) }}</headline>
      <meter-device-list
          :organization-id="organization.id"
          :count.sync="meterDevicesCount"
          :loaded.sync="meterDevicesLoaded"
      />
    </div>

  </div>
</template>

<script>

import ChargingPointList from "@/components/elements/ChargingPointList.vue";
import MeterDeviceList from "@/components/elements/MeterDeviceList.vue";


export default {
  components: {
    MeterDeviceList,
    ChargingPointList
  },

  data() {
    return {
      meterDevicesCount: 0,
      meterDevicesLoaded: false,
      type: 0,
    }
  },

  mounted() {
    this.type = this.$route.query.type ?? null
  },

  computed: {
    organization() {
      return this.$parent.organization ?? null
    }
  },

}
</script>

<style lang="scss">

</style>
