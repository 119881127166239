<template>
  <div class="custom-fields-editor">

    <v-dialog v-model="showFormModal" max-width="800">
      <v-form @submit.prevent="save">

        <v-card>
          <v-card-title>
            {{ edit ? $t('custom-field.edit-field') : $t('custom-field.add-field') }}
          </v-card-title>
          <v-divider />
          <v-card-text>
            <div class="my-6">


              <v-row>
                <v-col>
                  <form-field
                      :label="$t('label')"
                      v-model="form.label"
                      col-field="9"
                  />
                </v-col>
              </v-row>



              <v-row>
                <v-col>
                  <form-field
                      :label="$t('description')"
                      v-model="form.description"
                      rows="3"
                      col-field="9"
                      type="textarea"
                  />
                </v-col>
              </v-row>


              <v-row>
                <v-col>
                  <form-field
                      :label="$t('type')"
                      v-model="form.type"
                      col-field="9"
                      type="select-no-ac"
                      :items="types"
                  />
                </v-col>
              </v-row>
              <v-row v-if="form.type === 'choice'">
                <v-col>
                  <form-field
                      :label="$t('custom-field.possible-values')"
                      v-model="choices"
                      col-field="9"
                      :placeholder="$t('custom-field.choice-placeholder')"
                  />
                </v-col>
              </v-row>
              <v-row v-if="form.type === 'organization'">
                <v-col>
                  <form-field
                      type="tree-select-organization"
                      :label="$t('organization.organization')"
                      v-model="organizations"
                      multiple
                      clearable
                      col-field="9"
                  />
                </v-col>
              </v-row>
              <v-row >
                <v-col>
                  <form-field
                      :label="$t('default-value')"
                      v-model="form.options.default"
                      col-field="9"
                      :type="(form.type === 'bool') ? 'switch' : 'string'"
                  />
                </v-col>
              </v-row>
              <v-row >
                <v-col>
                  <form-field
                      type="switch"
                      :label="$t('custom-field.display-in-pdf-report')"
                      v-model="form.options.displayInPdfReport"
                      col-field="9"
                  />
                </v-col>
              </v-row>
              <v-row >
                <v-col>
                  <form-field
                      type="switch"
                      :label="$t('required')"
                      v-model="form.options.required"
                      col-field="9"
                  />
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-row class="ma-0">
              <v-col class="text-right" align-self="center">
                <v-btn text small @click.prevent="closeFormModal">
                  {{ $t('cancel') }}
                </v-btn>
                <v-btn class="ml-4" small  type="submit" :disabled="!canSaveModal">
                  {{ $t(selectedIndex !== null ? 'validate' : 'add') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>


    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="data"
          :items-per-page="9999"
          hide-default-footer
        >

          <template #item.name="{item}">
            <h3>{{ item.label }}</h3>
            <div>{{ item.description }} </div>
            <div v-if="item.options.default"><i>{{ $t('default-value') }}: {{ item.options.default }}</i></div>
          </template>

          <template #item.options="{index}">
            <v-menu>
              <template #activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="ml-3"
                    small
                    icon
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense >
                <v-subheader>{{ $t('actions').toLocaleUpperCase() }}</v-subheader>
                <v-list-item @click="openEditFieldModal(index)">
                  <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteField(index)">
                  <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

        </v-data-table>
      </v-col>
    </v-row>


    <v-row>
      <v-col>
        <v-btn small @click="openAddFieldModal">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('custom-field.add-field')}}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Tools from "@includes/tools";
import {v4 as uuidv4} from "uuid";
import RulesMixin from "@mixins/RulesMixin";

export default {
  data() {
    return {
      choices: null,
      selectedIndex: null,
      data: this.value,
      edit: false,
      showFormModal: false,
      organizations: [],
      headers: [
        {text: this.$tc('custom-field.field'), value: 'name', align: 'left'},
        {text: this.$tc('type'), value: 'type', width: 300},
        {text: '', value: 'options', width: 50},
      ],
      types: [
        {text: this.$tc('custom-field.types.text'), value: 'text'},
        {text: this.$tc('custom-field.types.paragraph'), value: 'paragraph'},
        {text: this.$tc('custom-field.types.number'), value: 'number'},
        {text: this.$tc('custom-field.types.bool'), value: 'bool'},
        {text: this.$tc('custom-field.types.choice'), value: 'choice'},
        {text: this.$tc('custom-field.types.organizations'), value: 'organization'},
      ],
      form: {
        id: null,
        name: null,
        label: null,
        description: null,
        type: 'text',
        options: {
          default: null,
          required: false,
          displayInPdfReport: false,
        },
      },
    }
  },

  mixins: [
      RulesMixin,
  ],

  model: {
    event: 'input',
    prop: 'value',
  },

  props: {
    value: {type: Array, default: () => ([])},
  },

  watch: {
    data(data) {
      this.$emit('input', data)
    },

    value(data) {
      this.data = data
    },

    'form.label': function(label) {
      if(!this.edit) {
        this.form.name = Tools.kebabize(label)
      }
    }
  },

  computed: {
    canSaveModal() {
      return this.form.label?.trim() && this.form.type
    },
  },

  methods: {

    closeFormModal() {
      this.showFormModal = false
    },


    openAddFieldModal() {
      this.selectedIndex = null
      this.form = {
        id: uuidv4(),
        type: 'text',
        options: {
          default: null,
          required: false,
        }
      }
      this.$nextTick(() => {
        this.showFormModal = true
      })
    },

    openEditFieldModal(index) {
      this.selectedIndex = index
      this.form = this.cloneObj(this.data[index])
      this.choices = this.form.options?.choices?.join(',')
      this.$nextTick(() => {
        this.showFormModal = true
      })
    },

    deleteField(index) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.data.splice(index, 1)
        }
      })
    },

    save() {
      if(this.canSaveModal) {

        if(this.choices) {
          if(this.form.type === 'choice') {
            this.form.options.choices = this.choices.split(',').map(s => s.trim())
          }
        }

        if(this.form.type === "listOrganizations") {
          this.form.options.organizations=this.organizations
        }

        if(this.selectedIndex !== null) {
          this.$set(this.data, this.selectedIndex, this.cloneObj(this.form))
        } else {
          this.data.push({
            id: this.form.id,
            name: this.form.name,
            label: this.form.label,
            description: this.form.description,
            type: this.form.type,
            options: this.form.options,
          })
        }

        this.data.sort((a,b) => {
          if(a.name > b.name) {
            return 1
          }
          if(a.name < b.name) {
            return -1
          }
          return 0
        })

        this.closeFormModal()
      }

    },
  }
}
</script>


<style lang="scss">
  .custom-fields-editor {
    .col {
      display: flex;
      justify-content: center;
    }
    .v-data-table {
      background: #eee !important;
      min-width: 60%;
      padding: 20px;
      border-radius: 10px;
      //box-shadow: 2px 2px 10px 0px #acacac;
      table {
        tr {
          td {
            padding: 20px 10px !important;
            font-weight: normal;
          }
          & + tr {
            td {
              border-top: 1px solid #ccc;
            }
          }
        }
      }
    }
  }
</style>
