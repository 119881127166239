<template>
  <v-tooltip
      nudge-top="5"
      bottom
      :content-class="'custom-tooltip ' + getOrganizationGroupTypeColor(item.type) + '--text'"
      :disabled="item.name === $t('member-group.types.' + item.type)"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
          class="mr-2 my-1" dark outlined  :color="getOrganizationGroupTypeColor(item.type)"
          v-bind="attrs"
          v-on="on"
          :to="editUrl"
          @click.stop

      >
        {{ item.name }}
      </v-chip>
    </template>
    <span >{{ $t("member-group.types." + item.type) }}</span>
  </v-tooltip>
</template>

<script>

export default {
  data() {
    return {

    }
  },

  props: {
    item: {type: Object, required: true},
    organizationId: String,
  },

  computed: {
    editUrl() {
      let to = {name: 'organizations.members.groups.edit', params: {memberGroupId: this.item.id}}

      if(this.item.organizationId) {
        to.params.id = this.item.organizationId
      }
      if(this.item?.organization?.id) {
        to.params.id = this.item.organization?.id
      }
      return to
    }
  }
}
</script>

<style lang="scss">

</style>
