<template>
  <v-menu left offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          class="ml-3"
          small
      >
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item :to="{name: 'organizations.members.types'}">
        <v-list-item-title>{{ $t('member-type.manage') }}</v-list-item-title>
      </v-list-item>
      <v-list-item :to="{name: 'organizations.members.groups'}">
        <v-list-item-title>{{ $t('member-group.manage') }}</v-list-item-title>
      </v-list-item>
      <v-list-item :to="{name: 'organizations.members.invitations'}">
        <v-list-item-title>{{ $t('member.invitation.manage') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {

}
</script>

<style lang="scss">

</style>
