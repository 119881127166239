<template>
  <div class="member-search mt-5">

    <v-breadcrumbs
        class="level-2"
        :items="[
            { text: 'Remboursement de frais', to: {name: 'organizations.expense-periods' }, exact: true},
            { text: periodName, to: {name: 'organizations.expense-periods.manage', params: {periodId: periodId} }, exact: true},
            { text: memberName },
        ]"
        divider=">"
    ></v-breadcrumbs>

    <v-row dense>
      <v-col class="text-right">
        <v-btn small @click="exportPdf">
          <v-icon left>mdi-file-pdf-box</v-icon>
          PDF
        </v-btn>
      </v-col>
    </v-row>


    <v-row class="mt-10">
      <v-col cols="6">
        <v-card>
          <v-card-text>
            <v-row dense>
              <v-col cols="4">
                {{ $t('name') }}
              </v-col>
              <v-col cols="8">
                <router-link :to="{name: 'member', params: {memberId: memberId}}">
                  <b>{{ customer.firstname }} {{ customer.lastname }}</b>
                </router-link>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="4">
                {{ $t('expense-refund.period') }}
              </v-col>
              <v-col cols="8">
                {{ periodName }}
              </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>

            <v-row dense>
              <v-col cols="4">
                {{ $t('expense-refund.charge-count') }}
              </v-col>
              <v-col cols="8">
                {{ cdrs.length }}
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card>
          <v-card-text>
            <v-row dense>
              <v-col cols="4">
                {{ $t('expense-refund.cdr-total') }}
              </v-col>
              <v-col cols="8">
                {{ numberFormat(cdrTotal) }} €
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="4">
                {{ $t('expense-refund.corrective-total') }}
              </v-col>
              <v-col cols="8">
                {{ numberFormat(correctiveTotal) }} €
              </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>

            <v-row dense>
              <v-col cols="4">
                <b>{{ $t('expense-refund.total') }}</b>
              </v-col>
              <v-col cols="8">
                <b>{{ numberFormat(refundTotal) }} €</b>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    <!-- List -->
    <v-row no-gutters class="mt-10">
      <v-col>
        <v-data-table
            class="member-list"
            :headers="headers"
            :items="cdrs"
            :options="options"
            :loading="loading"
        >

          <template #item.startTime="{item}">
            {{ item.startTime|formatDate }}
          </template>

          <template #item.stopTime="{item}">
            {{ item.stopTime|formatDate }}
          </template>

          <template #item.duration="{value}">
            {{ formatDuration(value) }}
          </template>

          <template #item.energy="{value}">
            {{ numberFormat(value/ 1000) }} kWh
          </template>

          <template #item.amount="{value}">
            {{ numberFormat(value) }} €
          </template>

          <template #item.status="{item}">
            <v-select
                v-model="item.status"
                :items="cdrStatues"
                hide-details
                @change="changeStatus(item)"
                :disabled="!isEditable"
            >
            </v-select>
          </template>

        </v-data-table>

      </v-col>
    </v-row>

    <headline class="mt-10">{{ $tc('expense-refund.corrective-line', 2) }}</headline>
    <v-row>
      <v-col class="text-right">
        <v-btn small @click="createCorrectiveLine" v-if="isEditable">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('add') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-10">
      <v-col>
        <v-data-table
            class="member-list"
            :headers="correctiveHeaders"
            :items="correctives"
            :loading="loading"
        >

          <template #item.options="{item}">
            <v-row>
              <v-col class="text-center">
                <v-btn icon small @click="editCorrectiveExpenseLine(item)" :disabled="!isEditable">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn class="ml-3" icon small @click="deleteCorrectiveExpenseLine(item)" :disabled="!isEditable">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>

          </template>

          <template #item.amount="{value}">
            {{ numberFormat(value) }} €
          </template>

        </v-data-table>

      </v-col>
    </v-row>

    <v-dialog max-width="600" v-model="correctiveLineModal" v-if="correctiveLine">
      <v-form @submit.prevent="saveCorrectiveLine">
        <v-card>
          <v-card-title>
            {{ $tc('expense-refund.corrective-line', 1) }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    v-model="correctiveLine.reason"
                    :label="$t('label')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    type="number"
                    v-model.number="correctiveLine.amount"
                    :label="$t('amount')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row class="ma-0">
              <v-col class="text-right">
                <v-btn small @click="correctiveLineModal = false">
                  {{ $t('cancel') }}
                </v-btn>
                <v-btn class="ml-3" small type="submit" color="primary">
                  {{ $t('save') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>




  </div>
</template>

<script>
import OrganizationRepository from "@repository/OrganizationRepository"
import MemberGroupRepository from "@repository/MemberGroupRepository"
import ExpenseRefundRepository from "@repository/ExpenseRefundRepository";
import dayjs from "dayjs";

export default {
  data() {
    return {
      customer: {},
      startDate: null,
      endDate: null,
      status: null,
      loading: false,
      expenseLine: [],
      correctives: [],
      cdrs: [],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['stopTime'],
        sortDesc: [false],
      },
      correctiveLineModal: false,
      correctiveLine: null,
    }
  },

  mounted() {
    this.loadData()
  },

  watch: {


  },

  computed: {

    headers() {
      return [
        {text: this.$t('expense-refund.charging-date'), value: 'startTime'},
        {text: this.$t('net'), value: 'amount'},
        {text: this.$t('energy'), value: 'energy'},
        {text: this.$t('duration'), value: 'duration'},
        {text: this.$t('status'), value: 'status', width: '200px'},
      ]
    },

    correctiveHeaders() {
      let headers = [
        {text: this.$t('label'), value: 'reason'},
        {text: this.$t('amount'), value: 'amount', width: 250},
      ]

      if(this.isEditable) {
        headers.push({value: 'options', sortable: false, width: 200})
      }

      return headers
    },

    organization() {
      return this.$parent.organization ?? null
    },

    memberId() {
      return this.$route.params.memberId
    },

    periodId() {
      return this.$route.params.periodId
    },

    periodName() {
      if(this.startDate) {
        let str = this.formatUtcDate(this.startDate) + ' > ' + this.formatUtcDate(this.endDate)
        if(this.status === 'DONE' || this.status === 'CANCELED') {
          str += ' ( ' + this.$t('expense-refund.status.' + this.status ) + ' )'
        }
        return str
      }
      return null
    },

    memberName() {
      if(this.customer?.firstname || this.customer?.lastname) {
        return this.customer.firstname + ' ' + this.customer.lastname
      }
      return null
    },

    cdrStatues() {
      return [
        { text: 'Accepté', value: 'APPROVED' },
        { text: 'Refusé', value: 'REJECTED' },
        { text: 'En attente', value: 'PENDING' },
      ]
    },

    statues() {
      return [
        { text: 'Accepté', value: 'accepted' },
        { text: 'En attente', value: 'pending' },
      ]
    },

    isEditable() {
      return this.status === 'PENDING' || this.status === 'IN_PROGRESS'
    },

    cdrTotal() {
      let total = 0
      this.cdrs.forEach(cdr => {
        // only accepted cdrs
        if(cdr.status === 'APPROVED') {
          total += cdr.amount
        }
      })
      return total
    },

    correctiveTotal() {
      let total = 0
      this.correctives.forEach(corrective => {
        // only accepted cdrs
        if(corrective.status === 'APPROVED') {
          total += corrective.amount
        }
      })
      return total
    },

    refundTotal() {
      return this.cdrTotal + this.correctiveTotal
    },

  },

  methods: {

    loadData() {
      this.showLoading(true)
      ExpenseRefundRepository.memberCdrs(this.periodId, this.memberId).then(response => {
        this.customer = response.customer
        this.startDate = response.startDate
        this.endDate = response.endDate
        this.status = response.status
        this.total = response.total
        this.cdrs = response.cdrs
        this.correctives = response.correctives
        this.showLoading(false)
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
        this.loading = false
      })
    },

    changeStatus(item) {
      ExpenseRefundRepository.changeLineStatus(item.id, item.status).then(response => {
        this.$dialog.notify.success(this.$t('update-success'))
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
      })
    },

    exportPdf() {
      this.showLoading(true)
      ExpenseRefundRepository.periodMemberPdf(this.periodId, this.memberId).then(() => {
        this.showLoading(false)
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
        this.showLoading(false)
      })
    },

    createCorrectiveLine() {
      this.correctiveLine = {
        amount: 0,
        reason: null,
      }
      this.correctiveLineModal = true
    },

    editCorrectiveExpenseLine(item) {
      this.correctiveLine = {
        id: item.id,
        amount: item.amount,
        reason: item.reason,
      }
      this.correctiveLineModal = true
    },

    deleteCorrectiveExpenseLine(item) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if(result) {
          this.showLoading(true)
          ExpenseRefundRepository.deleteCorrectiveLine(item.id).then(response => {
            this.notifySuccess('delete-success')
            this.showLoading(false)
            this.loadData()
          }).catch(e => {
            this.notifyError(e)
            this.showLoading(false)
          })
        }
      })
    },

    saveCorrectiveLine() {
      if(!this.correctiveLine) {
        return
      }

      this.showLoading(true)
      const data = {
        amount: this.correctiveLine.amount,
        reason: this.correctiveLine.reason,
      }
      if(this.correctiveLine.id) {
        ExpenseRefundRepository.updateCorrectiveLine(this.correctiveLine.id, data).then(response => {
          this.notifySuccess('update-success')
          this.showLoading(false)
          this.correctiveLineModal = false
          this.loadData()
        }).catch(e => {
          this.notifyError(e)
          this.showLoading(false)
        })
      } else {
        data.expensePeriodId = this.periodId
        data.memberId = this.memberId
        ExpenseRefundRepository.createCorrectiveLine(data).then(response => {
          this.notifySuccess('update-success')
          this.showLoading(false)
          this.correctiveLineModal = false
          this.loadData()
        }).catch(e => {
          this.notifyError(e)
          this.showLoading(false)
        })
      }
    }
  },
}
</script>

<style lang="scss">
.member-search {
  min-height: 1000px;
  .member-list {
    td, th {
      cursor: pointer;
      &.text-start {
        text-align: left !important;
      }
    }
  }
}
</style>
