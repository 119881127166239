<template>
  <div style="margin-top: 20px">
    <v-row class="filter-by-letter">
      <v-col class="text-center">
        <a
            v-for="letter in alphabet"
            :key="letter"
            @click="selectLetter(letter)"
            :class="{'active': selectedLetter === letter}">
          {{ letter }}
        </a>
        <a @click="selectLetter('')" style="font-size: 16px">
          {{ $t('all') }}
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    selectedLetter: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
    };
  },
  methods: {
    selectLetter(letter) {
      this.$emit('update:startBy', letter);
    }
  }
};
</script>

<style lang="scss">
.filter-by-letter {
  a {
    padding: 4px 10px;
    cursor: pointer;
    font-size: 16px;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    &.active {
      background: var(--v-primary-base);
      border-radius: 4px;
      color: white;
    }
  }
}
</style>
