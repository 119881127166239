<template>
  <div class="organization-overview  pa-10" v-if="organization">


    <headline>
      {{ $t('informations') }}
    </headline>

    <div class="grid">
      <v-row>
        <v-col class="label" sm="3">
          {{ $t('name') }} :
        </v-col>
        <v-col sm="3">
          {{ organization.name ? organization.name : '-' }}
          <v-btn class="ml-5" small :to="{name: 'organizations.edit'}">
            <v-icon left>mdi-cog</v-icon>
            {{ $t('edit')}}
          </v-btn>
        </v-col>
        <v-col class="label" sm="3">
          {{ $t('account-number') }} :
        </v-col>
        <v-col sm="3">
          {{ organization.accountNumber}}
        </v-col>
      </v-row>



      <v-row  v-if="organization.parentId && allowedOrganizationIds.includes(organization.parentId)">
        <v-col class="label" sm="3">
          {{ $t('organization.parent-organization') }} :
        </v-col>
        <v-col sm="9">
          <v-btn small :to="{name: 'organizations.detail', params: {id: organization.parentId }}">
            <v-icon left>mdi-arrow-right</v-icon>
            {{ cleanedPath(organization.path.replace('/' + organization.name , '')) }}
          </v-btn>
        </v-col>

      </v-row>




      <v-row>
        <v-col class="label" sm="3">
          {{ $t('organization.registration-number') }} :
        </v-col>
        <v-col sm="3">
          {{ organization.registrationNumber ? organization.registrationNumber : '-'}}
        </v-col>
      </v-row>


      <v-row>
        <v-col class="label" sm="3">
          {{ $t('vat-number') }} :
        </v-col>
        <v-col sm="3">
          <v-tooltip bottom v-if="organization.verifiedByVies">
            <template #activator="{on, attrs}">
              <div v-on="on" v-bind="attrs">
                {{ organization.vatNumber ? organization.vatNumber : '-'}} <v-icon class="ml-3" color="success">mdi-check-decagram</v-icon>
              </div>
            </template>
            <div class="text-center">
              {{ $t('organization.verified-by-vies') }}

            </div>
          </v-tooltip>
          <v-tooltip bottom  v-else>
            <template #activator="{on, attrs}">
              <div v-on="on" v-bind="attrs">
                {{ organization.vatNumber ? organization.vatNumber : '-'}} <v-icon class="ml-3" color="red">mdi-alert-decagram</v-icon>
              </div>
            </template>
            <div class="text-center">
              {{ $t('organization.vat-number-invalid') }}
            </div>
          </v-tooltip>
        </v-col>
        <template v-if="!organization.verifiedByVies" >
          <v-col class="label" sm="3">
            {{ $t('organization.billing-generic-account') }} :
          </v-col>
          <v-col sm="3">
            <v-switch disabled v-model="organization.useBillingGenericAccount"></v-switch>
          </v-col>
        </template>
      </v-row>

      <v-row>
        <v-col class="label" sm="3">
          {{ $t('organization.cpo-private') }} :
        </v-col>
        <v-col sm="3">
          <v-switch disabled v-model="organization.cpoPrivate"></v-switch>
        </v-col>
        <v-col class="label" sm="3">
          {{ $t('organization.allow-deferred-payment') }} :
        </v-col>
        <v-col sm="3">
          <v-switch disabled v-model="organization.empAllowDeferredPayment"></v-switch>
        </v-col>
      </v-row>


      <v-row>
        <v-col class="label" sm="3">
          {{ $t('organization.gireve-emsp-roaming') }} :
        </v-col>
        <v-col sm="1">
          <v-switch disabled v-model="organization.gireveOcpiEmp"></v-switch>
        </v-col>
        <v-col class="label" sm="2">
          {{ $t('organization.gireve-cpo-roaming') }} :
        </v-col>
        <v-col sm="1">
          <v-switch disabled v-model="organization.gireveOcpiCpo"></v-switch>
        </v-col>
        <v-col class="label" sm="2">
          {{ $t('organization.hubject-roaming') }} :
        </v-col>
        <v-col sm="1">
          <v-switch disabled v-model="organization.hubject"></v-switch>
        </v-col>
      </v-row>

    </div>


    <div class="mt-16">
      <headline>
        {{ $tc('organization.sub-organization', 2) }}
      </headline>
      <v-row>
        <v-col class="text-right">
          <v-btn small :to="{name: 'organizations.add', query: {'oid': this.organization.id}}">
            <v-icon left>mdi-plus</v-icon>
            {{ $t('organization.add-organization')}}
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <search-field
              :placeholder="$t('organization.search-organization')"
              :search.sync="search"
              :options.sync="options"
              clearable
          >
          </search-field>
        </v-col>
      </v-row>


      <v-row no-gutters class="mt-10">
        <v-col>
          <v-data-table
              class="organization-list"
              :headers="[
                {text: this.$t('name'), value: 'name', align: 'left'},
                {text: this.$t('account-number'), value: 'accountNumber', width: 150},
                {text: this.$tc('operator.operator'), value: 'cpoOperator.id', width: 100},
              ]"
              :items="items"
              :loading="loading"
              :options.sync="options"
              :server-items-length="total"
              @click:row="row => $router.push({name: 'organizations.detail', params: {id: row.id}})"
          >
            <template #item.name="{item}">
              <router-link :to="{name: 'organizations.detail', params: {id: item.id}}">
                <v-icon left>{{ commonIcons().organization.type[item.type] }}</v-icon>
                <span style="font-weight: 300;">{{ item.path.substr(0, item.path.length - item.name.length) }}</span><span class="red--text">{{ item.name }}</span>
              </router-link>
            </template>

          </v-data-table>

        </v-col>
      </v-row>
    </div>

  </div>
</template>

<script>

import OrganizationRepository from "@repository/OrganizationRepository";

export default {

  data() {
    return {
      search: '',
      selectedItem: null,
      items: [],
      loading: false,
      options: null,
      total: 0,
    }
  },

  watch: {
    search() {
      this.loadChildren()
    },

    options() {
      this.loadChildren()
    },

    'organization.id'() {
      this.loadChildren()
    },
  },

  computed: {
    organization() {
      return this.$parent.organization ?? null
    },

    allowedOrganizationIds() {
      const allowedId = []
      let founded = false;
      if(this.organization) {
        for(let i = 0; i < this.organization.hierarchies.length; i++) {
          if(founded || this.currentUser.organizations.includes(this.organization.hierarchies[i])) {
            allowedId.push(this.organization.hierarchies[i])
            founded = true
          }
        }
      }
      return allowedId
    },

  },

  methods: {

    cleanedPath(path) {
      let newPath = ''
      let pathPart = path.split('/')
      if(this.organization) {
        for(let i = 0; i < this.organization.hierarchies.length && i < pathPart.length; i++) {
          if(this.allowedOrganizationIds.includes(this.organization.hierarchies[i])) {
            if(newPath) {
              newPath += '/'
            }
            newPath += pathPart[i]
            if(this.organization.hierarchies[i] === this.organization.id) {
              break;
            }
          }
        }
      }
      return newPath
    },

    loadChildren() {
      this.loading = true

      let data = {
        searchText: this.search,
      }

      if(this.organization?.id) {
        data.parentId = this.organization?.id
      }

      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage


      if (this.options.sortBy.length > 0) {
        data.orderBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.orderDirection = this.options.sortDesc[0] ? 'desc' : 'asc'
      }

      OrganizationRepository.search(data).then(results => {
        this.total = results.total
        this.items = results.items
        this.loading = false
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
        this.loading = false
      })
    },

  }
}
</script>

<style lang="scss">


.organization-overview {

}

</style>
