<template>
  <div class="subscription-licences pa-10" v-if="subscription">

    <v-breadcrumbs
        class="level-2"
        :items="[
            {text: $tc('subscription.subscription', 2), to: {name: 'organizations.subscriptions'}, exact: true},
            {text: localizedString(subscription.subscriptionPlan.label), to: {name: 'organizations.subscriptions.edit'} },
            {text: $t('subscription.manage-licences') },
        ]"
        divider=">"
    ></v-breadcrumbs>


    <subscription-licences-list :subscription-id="subscription.id"></subscription-licences-list>


  </div>
</template>

<script>


import SubscriptionLicencesList from "@/components/elements/SubscriptionLicencesList.vue";
import SubscriptionRepository from "@repository/SubscriptionRepository";

export default {
  components: {
    SubscriptionLicencesList,
  },

  data() {
    return {
      subscription: null,
    }
  },



  mounted() {
    this.loadData()
  },

  watch: {

  },

  computed: {


  },

  methods: {
    loadData() {
      this.showLoading(true)
      SubscriptionRepository.get(this.$route.params.subscriptionId).then(data => {
        this.subscription = data
        this.showLoading(false)
      }).catch(err => {
        this.$dialog.notify.error(err.response.data?.message || err.message)
        this.showLoading(false)
      })
    },
  },

}
</script>

<style lang="scss">

</style>
