<template>
  <div>
    <report-usages-list :organization-id="organization?.id" />
  </div>
</template>

<script>
import ReportUsagesList from "@/components/elements/ReportUsagesList.vue";

export default {
  components: {
    ReportUsagesList
  },
  data() {
    return {

    }
  },

  computed: {
    organization() {
      return this.$parent.organization ?? null
    },

  },


  methods: {

  }
}
</script>

<style lang="scss">

</style>