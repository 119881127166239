<template>
  <div class="organization-search  pa-10">


    <v-row>
      <v-col class="text-right">
        <v-btn small :to="{name: 'organizations.add', query: {'oid': this.organization.id}}">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('organization.add-organization')}}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <search-field
            :placeholder="$t('organization.search-organization')"
            :search.sync="search"
            :options.sync="options"
            clearable
        >
        </search-field>
      </v-col>
    </v-row>


    <v-row no-gutters class="mt-10">
      <v-col>
        <v-data-table
            class="organization-list"
            :headers="headers"
            :items="items"
            :loading="loading"
            :options.sync="options"
            :server-items-length="total"
            @click:row="row => $router.push({name: 'organizations.detail', params: {id: row.id}})"
        >
          <template #item.name="{item}">
            <router-link :to="{name: 'organizations.detail', params: {id: item.id}}">
              <v-icon left>{{ commonIcons().organization.type[item.type] }}</v-icon>
              <span style="font-weight: 300;">{{ item.path.substr(0, item.path.length - item.name.length) }}</span><span class="red--text">{{ item.name }}</span>
            </router-link>
          </template>

        </v-data-table>

      </v-col>
    </v-row>

  </div>
</template>

<script>
import OrganizationRepository from "@repository/OrganizationRepository";

export default {
  data() {
    return {
      search: '',
      items: [],
      loading: false,
      options: null,
      total: 0,
      headers: [
        {text: this.$t('name'), value: "name"},
        {text: this.$t('account-number'), value: "accountNumber", width: 150},
        {text: this.$tc('operator.operator'), value: "cpoOperator.id", width: 100},
      ],
    }
  },

  computed: {
    organization() {
      return this.$parent.organization ?? null
    }
  },

  watch: {
    search() {
      this.loadData()
    },

    options() {
      this.loadData()
    },

  },

  methods: {
    onSelectItem(obj) {
      console.log('onSelectItem', obj)
    },

    loadData() {
      this.loading = true

      let data = {
        searchText: this.search,
      }

      if(this.organization?.id) {
        data.parentId = this.organization?.id
      }

      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage


      if (this.options.sortBy.length > 0) {
        data.orderBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.orderDirection = this.options.sortDesc[0] ? 'desc' : 'asc'
      }

      OrganizationRepository.search(data).then(results => {
        this.total = results.total
        this.items = results.items
        this.loading = false
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
        this.loading = false
      })
    },
  }
}
</script>

<style lang="scss">
.organization-search {
  min-height: 1000px;
  .organization-list {
    td, th {
      cursor: pointer;
      &.text-start {
        text-align: left !important;
      }
      a {
        text-decoration: none;
      }
    }
  }
}
</style>
