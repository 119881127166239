<template>
  <div class="customer-detail">

    <v-breadcrumbs
        class="level-2"
        :items="[
            {text: $tc('member.member', 2), to: {name: 'organizations.members'}, exact: true},
            {text: $t('member-type.manage'), to: {name: 'organizations.members.types'}, exact: true },
            {text: $t('member-type.' + (edit ? 'edit' : 'add')) },
        ]"
        divider=">"
    ></v-breadcrumbs>


    <v-form ref="form" @submit.prevent="save">

      <headline>
        {{ $tc("member-type.title") }}
      </headline>
      <v-row>
        <v-col>
          <form-field
              :label="$t('name')"
              v-model="form.name"
              col-field="9"
          />
        </v-col>
      </v-row>

      <headline class="mt-10">
        {{ $tc("custom-field.custom-field", 2) }}
      </headline>
      <v-row>
        <v-col>
          <custom-fields-editor v-model="form.customFields" />
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col class="text-center">
          <v-btn type="submit" color="primary">
            {{ $t(edit ? 'save' : 'create') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import RulesMixin from "@mixins/RulesMixin";
import MemberTypeRepository from "@repository/MemberTypeRepository";
import CustomFieldsEditor from "@blocks/CustomFieldsEditor.vue";
import {EventBus} from "@includes/EventBus";

export default {
  components: {CustomFieldsEditor},

  data() {
    return {
      form: {
        name: null,
      }
    }
  },

  mixins: [
    RulesMixin,
  ],

  mounted() {
    if (!this.currentUser.permissions.includes('ORGANIZATION_WRITE')) {
      this.$router.replace({name: 'dashboard'})
      return
    }
    if(this.edit) {
      this.loadMemberType()
    }
  },

  computed: {

    organization() {
      return this.$parent.organization ?? null
    },

    edit() {
      return !!this.$route.params.memberTypeId
    },

    id() {
      return this.$route.params.memberTypeId
    },
  },

  methods: {
    loadMemberType() {
      this.showLoading(true)
      MemberTypeRepository.get(this.id).then(data => {
        this.form = data
        this.showLoading(false)
      }).catch(err => {
        this.$dialog.notify.error(err.response.data?.message || err.message)
        this.showLoading(false)
      })
    },

    save() {
      if (this.$refs.form.validate()) {

        this.showLoading(true)
        let data = this.form

        if(this.edit) {
          MemberTypeRepository.update(this.id, data).then(result => {
            this.$dialog.notify.success(this.$t('update-success'))
            this.showLoading(false)
            this.$router.push({name: 'organizations.members.types'})
            EventBus.$emit('reloadView')
          }).catch(error => {
            this.showLoading(false)
            this.$dialog.notify.error(error.response?.data?.message ?? error.message)
          })
        } else {
          data.organizationId = this.organization.id
          MemberTypeRepository.create(data).then(result => {
            this.$dialog.notify.success(this.$t('update-success'))
            this.showLoading(false)
            this.$router.replace({name: 'organizations.members.types'})
          }).catch(error => {
            this.showLoading(false)
            this.$dialog.notify.error(error.response?.data?.message ?? error.message)
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">

</style>
