<template>
  <div class="member-invitations">

    <v-breadcrumbs
        class="level-2 pt-0"
        :items="[
            {text: $tc('member.member', 2), to: {name: 'organizations.members'}, exact: true},
            {text: $t('member.invitation.manage') },
        ]"
        divider=">"
    ></v-breadcrumbs>

    <!-- Add button -->
    <v-row>
      <v-col class="text-right">
        <v-btn small @click="openInviteMember()">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('member.invite-member') }}
        </v-btn>
        <settings-button />
      </v-col>
    </v-row>

    <!-- Search -->
    <v-row>
      <v-col>
        <search-field
            :placeholder="$t('member.invitation.search')"
            :search.sync="search"
            :options.sync="options"
            clearable
        >
        </search-field>
      </v-col>
    </v-row>

    <!-- List -->
    <v-row no-gutters class="mt-10">
      <v-col>
        <v-data-table
            class="invitations-list"
            :headers="headers"
            :items="items"
            :loading="loading"
            :options.sync="options"
            :server-items-length="total"
            @click:row="row => $router.push({ path: 'invitations/manage/' + row.id })"
        >
          <template #item.status="{ value }">
            {{ $t('invitation.status.' + value) }}
          </template>

          <template #item.groups="{ item }">
            <member-group-type :item="groups[group]" :key="group" v-for="group in item.groups"></member-group-type>
          </template>

          <template #item.createdAt="{ value }">
            {{ value|formatDate }}
          </template>

          <template #item.lastSentAt="{ value }">
            {{ value|formatDate }}
          </template>

          <template #item.actions="{ item }">
            <!-- Resend -->
            <v-btn icon @click.stop="resendInvitation(item.id)" :title="$t('member.invitation.resend')" v-if="item.status === 'PENDING'">
              <v-icon small>mdi-refresh</v-icon>
            </v-btn>

            <!-- Delete -->
            <v-btn icon @click.stop="deleteInvitation(item.id)" :title="$t('delete')">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>

        </v-data-table>

      </v-col>
    </v-row>

    <!-- Modal: Invitation membre -->
    <invite-member-modal
        v-if="organization"
        ref="inviteMember"
        :organization="organization"
        @invitationSent="loadData('modal event')"
    ></invite-member-modal>
  </div>
</template>

<script>
import InviteMemberModal from "@/components/modals/InviteMemberModal.vue";
import MemberInvitationRepository from "@repository/MemberInvitationRepository"
import MemberGroupType from "@blocks/MemberGroupType.vue";
import MemberGroupRepository from "@repository/MemberGroupRepository";
import SettingsButton from "@pages/Organization/Detail/Member/elements/SettingsButton.vue";

export default {
  components: {
    SettingsButton,
    MemberGroupType,
    InviteMemberModal,
  },

  data() {
    return {
      inviteMemberModal: {
        displayed: true
      },
      search: '',
      loading: false,
      selectedItem: null,
      items: [],
      options: null,
      total: 0,
      headers: [
        {text: this.$t('member.invitation.identifier'), value: 'invitationIdentifier'},
        {text: this.$t('lastname'), value: 'lastname'},
        {text: this.$t('firstname'), value: 'firstname'},
        {text: this.$tc('member-type.title'), value: 'memberType.name'},
        {text: this.$tc('group', 2), value: 'groups'},
        {text: this.$t('status'), value: 'status'},
        {text: this.$t('member.invited-at'), value: 'createdAt'},
        {text: this.$t('member.last-sent-at'), value: 'lastSentAt'},
        {text: '', value: 'actions', sortable: false},
      ],
      groups: [],
    }
  },

  mounted() {
    this.loadGroups()
  },

  watch: {
    search() {
      this.loadData('search')
    },

    options() {
      this.loadData('options')
    },

    selectedItem(val) {
      this.$router.push({name: 'organizations.members.types.edit', params: {memberTypeId: val.id} })
    },
  },

  computed: {
    organization() {
      return this.$parent.organization ?? null
    }
  },

  methods: {

    loadData(source) {
      this.loading = true

      let data = {
        searchText: this.search,
      }
      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage

      data.organizationId = this.organization.id

      if (this.options.sortBy.length > 0) {
        data.orderBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.orderDirection = this.options.sortDesc[0] ? 'desc' : 'asc'
      }

      MemberInvitationRepository.search(data).then(response => {
        this.total = response.total
        this.items = response.items
        this.loading = false
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
        this.loading = false
      })
    },

    loadGroups() {
      let data = {
        organizationId: this.organization.id
      }

      MemberGroupRepository.search(data).then(response => {
        // Stock les groupes dans un objet indexé par ID
        this.groups = response.items.reduce((acc, group) => {
          acc[group.id] = group;
          return acc;
        }, {});
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
      })
    },

    deleteInvitation(id) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.loading = true
          MemberInvitationRepository.delete(id).then(() => {
            this.loadData()
          }).catch(e => {
            this.$dialog.notify.error(e.response?.data?.message || e.message)
            this.loading = false
          })
        }
      })
    },

    resendInvitation(id) {
      this.showLoading(true)
      MemberInvitationRepository.resendMemberInvitation(id).then(() => {
        this.loadData()
        this.showLoading(false)
        this.$dialog.notify.success(this.$t('member.invitation.sent'))
      }).catch(e => {
        this.showLoading(false)
        this.$dialog.notify.error(e.response?.data.message ?? e.message)
      })
    },

    openInviteMember() {
      this.$refs.inviteMember.open()
    },
  }, // /methods
}
</script>

<style lang="scss">
.member-invitations {
  min-height: 1000px;

  .invitations-list {
    td, th {
      cursor: pointer;

      &.text-start {
        text-align: left !important;
      }
    }
  }
}
</style>
