<template>
  <div class="subscription-licences-list">
    <headline>
      {{ $tc('subscription.licence', 2) }}
    </headline>

    <v-row >
      <v-col>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                :label="$t('search')"
                prepend-icon="mdi-filter"
                hide-details
                clearable
            />
          </v-col>
          <v-col  v-if="$slots.filters">
            <slot name="filters"></slot>
          </v-col>
          <v-col cols="auto" v-if="licenceInactivesCount > 0">
            <v-select
                v-model="showActiveLicence"
                hide-details
                :items="[
                  {text: $t('active'), value: true},
                  {text: $t('inactive'), value: false},
                ]"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto" class="text-right d-flex align-center">
        <v-btn small @click="openAddLicenceForm">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('subscription.add-licence')}}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="licencesFiltered"
          :loading="loading"
          :options="{
            sortBy: [],
            sortDesc: [],
          }"
        >
          <template #item.name="{item}">
            {{ item.subscriptionPlan.name }}
          </template>

          <template #item.config="{item}">
            <div>
              <v-chip v-if="item.formula" class="mb-2 mr-2" color="blue" dark small>{{ item.formula.name }}</v-chip>
              <v-chip class="mb-2 mr-2" color="orange" dark small v-for="option in item.options" :key="option.id">{{ option.name }}</v-chip>
              <template v-if="item.quantity > 1">({{ $tc('subscription.licence-units-count-display', item.quantity, {quantity: item.quantity}) }})</template>
            </div>
<!--            <v-chip class="mb-2 mr-2" color="grey" dark small v-for="property in itemProperties(item)" :key="property.value">{{ property.text }}</v-chip>-->
<!--            <v-chip class="mb-2 mr-2" color="blue" dark small v-for="filter in itemFilters(item)" :key="filter.value">{{ filter.text }}</v-chip>-->
          </template>

          <template #item.activationStartDate="{value}">
            {{ value | formatDate($t('format_date')) }}
          </template>

          <template #item.subscribedAt="{value}">
            {{ value | formatDate($t('format_date')) }}
          </template>

          <template #item.billingStartDate="{value}">
            {{ value | formatDate($t('format_date')) }}
          </template>

          <template #item.unsubscribedAt="{value}">
            {{ value | formatDate($t('format_date')) }}
          </template>

          <template #item.related.label="{item}">
            <v-icon left v-if="item.related?.type === 'member'">ico-carplug</v-icon>
            <v-icon left v-if="item.related?.type === 'organization'">ico-organization-menu</v-icon>
            <v-icon left v-if="item.related?.type === 'charging-point'">ico-menu-charging-point</v-icon>
            <v-icon left v-if="item.related?.type === 'meter-device'">mdi-counter</v-icon>

            <router-link v-if="item.related?.type === 'charging-point'" :to="{name: 'charging-points.detail', params: {id: item.related.id}}">
              {{ item.related.label }}
            </router-link>

            <router-link v-if="item.related?.type === 'meter-device'" :to="{name: 'meter-device.detail', params: {id: item.related.id}}">
              {{ item.related.label }}
            </router-link>
            <router-link v-if="item.related?.type === 'organization'" :to="{name: 'organizations.detail', params: {id: item.related.id}}">
              {{ item.related.label }}
            </router-link>
            <router-link v-if="item.related?.type === 'member'" :to="{name: 'member', params: {id: item.related.id}}">
              {{ item.related.label }}
            </router-link>



            <span class="font-weight-light" v-if="item.related?.members?.length > 0">
              (<span v-for="member in item.related.members" :key="member.id">
                <router-link  :to="{name: 'member', params: {memberId: member.id}}">
                  {{ member.name }}
                </router-link>
              </span>)
            </span>

          </template>

          <template #item.active="{value}">
            <v-icon v-if="value" color="green">mdi-check</v-icon>
            <v-icon v-else color="red">mdi-close</v-icon>
          </template>

          <template #item.actions="{item}">
            <v-btn v-if="item.active" small icon @click="openEditLicenceForm(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn v-if="!item.active" small icon @click="cancelUnsubscription(item)">
              {{ $t('subscription.cancel-unsubscribe') }}
            </v-btn>
          </template>


        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="showUnsubscribeModal" max-width="600">
      <v-card>
        <v-card-title>
          {{ $t('subscription.terminate-a-licence') }}
        </v-card-title>
        <v-card-text>
          <form-field
            type="date"
            :label="$t('subscription.terminate-date')"
            v-model="unsubscribeDate"
          ></form-field>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col class="text-right">
              <v-btn class="mr-3" small @click="showUnsubscribeModal = false">{{ $t('cancel') }}</v-btn>
              <v-btn small color="primary" @click="unsubscribe(addLicenceForm.id, unsubscribeDate)">{{ $t('subscription.terminate-a-licence') }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showLicenceFormModal" max-width="1000" scrollable>
      <v-form ref="form" @submit.prevent="saveLicence" v-if="addLicenceForm">
        <v-card>
          <v-card-title>
            {{ $t(isEditLicence ?  'subscription.edit-licence' : 'subscription.add-licence') }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-select
                  :label="$tc('subscription.subscription')"
                  v-model="addLicenceForm.subscriptionId"
                  :items="subscriptions"
                  item-value="id"
                  item-text="name"
                  hide-details
                  :disabled="isEditLicence"
                ></v-select>
              </v-col>
            </v-row>

            <template v-if="subscription">
              <v-row v-if="['device', 'charging-point', 'energy-provider'].includes(subscription.licenceType)">
                <v-col cols="12">
                  <template v-if="!isEditLicence">

                    <v-radio-group v-model="selectedDeviceType">
                      <v-row>
                        <v-col cols="auto">
                          <v-radio
                              :label="$t('charging-point.charging-point')"
                              value="charging-point"
                          />
                        </v-col>
                        <v-col cols="auto">
                          <v-radio
                              :label="$tc('meter-device.meter-device')"
                              value="meter-device"
                          />
                        </v-col>
                      </v-row>
                    </v-radio-group>

                    <charging-point-select
                        v-if="selectedDeviceType === 'charging-point'"
                        :label="$t('charging-point.charging-point')"
                        v-model="chargingPointIds"
                        multiple
                        :limit="-1"
                        :filter-query="{
                          organizationId: subscription.organizationId,
                        }"
                        :exclude="excludeChargingPointIds"
                        :disabled="!!chargingPointId"
                    />
                    <meter-device-select
                        v-if="selectedDeviceType === 'meter-device'"
                        :label="$tc('meter-device.meter-device', 2)"
                        v-model="meterDeviceIds"
                        multiple
                        :limit="-1"
                        :filter-query="{
                          organizationId: subscription.organizationId,
                        }"
                        :exclude="excludeMeterDeviceIds"
                        :disabled="!!meterDeviceId"
                    />
                  </template>
                  <v-text-field
                      v-else
                      :label="$t('charging-point.charging-point')"
                      :value="addLicenceForm.related?.label"
                      hide-details
                      disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="['organization'].includes(subscription.licenceType)">
                <v-col cols="12">
                  <organization-select
                      v-model="addLicenceForm.organizationId"
                  />
                </v-col>
              </v-row>


              <v-row v-if="isEditLicence">
                <v-col cols="12">
                  <v-text-field
                      type="number"
                      :label="$t('subscription.licence-units-count')"
                      v-model.number="addLicenceForm.quantity"
                  />
                </v-col>
              </v-row>


              <v-row v-if="subscription.formulas.length > 0">
                <v-col cols="12">
                  <v-select
                      :label="$tc('subscription.formula', 1)"
                      v-model="addLicenceForm.subscriptionFormulaId"
                      :items="subscription.formulas"
                      item-value="id"
                      hide-details
                      :readonly="subscription.formulas.length === 1"
                  >
                    <template #item="{item}">
                      <div>
                        {{ item.name }}
                        <div>
                          <v-chip small v-for="filter in item.filters" :key="filter">{{ $t(`enums.subscription-plan-filters.${filter}`) }}</v-chip>
                          <v-chip small v-for="property in item.properties" :key="property">{{ $t(`enums.subscription-plan-properties.${property}`) }}</v-chip>
                        </div>
                      </div>
                    </template>

                    <template #selection="{item}">
                      <div>
                        {{ item.name }}
                        <div>
                          <v-chip small v-for="filter in item.filters" :key="filter">{{ $t(`enums.subscription-plan-filters.${filter}`) }}</v-chip>
                          <v-chip small v-for="property in item.properties" :key="property">{{ $t(`enums.subscription-plan-properties.${property}`) }}</v-chip>
                        </div>
                      </div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row v-if="subscription.options.length > 0">
                <v-col cols="12">
                  <v-select
                      :label="$tc('subscription.option', 2)"
                      v-model="addLicenceForm.subscriptionOptions"
                      :items="subscription.options"
                      multiple
                      :menu-props="{
                        bottom: true,
                        'offset-y': true,
                      }"
                      item-value="id"
                      hide-details
                  >
                    <template #item="{item}">
                      <div>
                        {{ item.name }}
                        <div>
                          <v-chip small v-for="property in item.properties" :key="property">{{ $t(`enums.subscription-plan-properties.${property}`) }}</v-chip>
                        </div>
                      </div>
                    </template>

                    <template #selection="{item}">
                      <div>
                        {{ item.name }}
                        <div>
                          <v-chip small v-for="property in item.properties" :key="property">{{ $t(`enums.subscription-plan-properties.${property}`) }}</v-chip>
                        </div>
                      </div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>


              <v-row>
                <v-col cols="12" :md="canSelectStartBillingType && canShowStartBillingDate ? 6 : 12" v-if="canSelectStartBillingType">
                  <v-select
                      :label="$t('subscription.start-billing-at')"
                      v-model="addLicenceForm.startBillingAt"
                      :items="billingStartDateAtItems"
                  />
                </v-col>
                <v-col cols="12" :md="canSelectStartBillingType ? 6 : 12" v-if="canShowStartBillingDate">
                  <date-picker
                      :label="$t('subscription.billing-start-date')"
                      v-model="addLicenceForm.billingStartDate"
                      clearable
                      :disabled="!canSetStartBillingDate"
                  />
                </v-col>
              </v-row>

              <v-row v-if="isEditLicence">
                <v-col cols="12">
                  <date-picker
                      :label="$t('subscription.activation-start-date')"
                      v-model="addLicenceForm.activationStartDate"
                  />
                </v-col>
              </v-row>

              <template v-if="['energy-provider'].includes(subscription.licenceType)">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-select
                        v-model="billingReceiptType"
                        :items="[
                          {text: $tc('member.member'), value: 'member'},
                          {text: $t('organization.organization'), value: 'organization'},
                        ]"
                        :label="$t('subscription.billing-type')"
                    />
                  </v-col>
                  <v-col cols="12" md="6" v-if="billingReceiptType === 'organization'">
                    <organization-select
                        :label="$t('organization.organization')"
                        v-model="addLicenceForm.billingOrganizationId"
                        selection-with-no-extra
                        :rules="[commonRules.required]"
                    />
                  </v-col>
                  <v-col cols="12" md="6"  v-if="billingReceiptType === 'member'">
                    <member-select
                        :label="$tc('member.member')"
                        :organization-id="subscription.organizationId"
                        v-model="addLicenceForm.billingMemberId"
                        selection-with-no-extra
                        :rules="[commonRules.required]"
                    />
                  </v-col>
                </v-row>
              </template>

            </template>
          </v-card-text>

          <v-divider class="mt-6"></v-divider>

          <v-card-actions>
            <v-row class="ma-0">
              <v-col class="text-left" v-if="addLicenceForm?.id">
                <v-btn class="mr-3" small @click="openUnsubscribeModal">{{ $t('subscription.terminate-a-licence') }}</v-btn>
              </v-col>
              <v-col class="text-right">
                <v-btn class="mr-3" small @click="closeLicenceFormModal">{{ $t('cancel') }}</v-btn>
                <v-btn small color="primary" type="submit" :disabled="!canSaveLicence || loadingSaveLicence" :loading="loadingSaveLicence">{{ $t(isEditLicence ? 'update' : 'add') }}</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

  </div>
</template>

<script>
import SubscriptionRepository from "@repository/SubscriptionRepository";
import ChargingPointSelect from "@blocks/Select/ChargingPointSelect.vue";
import OrganizationSelect from "@blocks/Select/OrganizationSelect.vue";
import dayjs from "dayjs";
import MemberSelect from "@blocks/Select/MemberSelect.vue";
import RulesMixin from "@mixins/RulesMixin";
import MeterDeviceSelect from "@blocks/Select/MeterDeviceSelect.vue";

export default {
  components: {
    OrganizationSelect,
    ChargingPointSelect,
    MemberSelect,
    MeterDeviceSelect,
  },
  data() {
    return {
      search: "",
      showActiveLicence: true,
      licences: [],
      loading: false,
      billingReceiptType: 'member',
      subscriptionStruct: null,
      showUnsubscribeModal: false,
      unsubscribeDate: null,
      showLicenceFormModal: false,
      selectedLicence: null,
      addLicenceForm: null,
      chargingPointIds: [],
      meterDeviceIds: [],
      loadingSaveLicence: false,
      selectedDeviceType: 'charging-point',
    }
  },

  mixins: [
    RulesMixin,
  ],

  props: {
    subscriptionId: String,
    organizationId: String,
    memberId: String,
    chargingPointId: String,
    meterDeviceId: String,
  },

  mounted() {
    this.loadSubscriptionStruct()
    this.loadData()
  },

  watch: {
    subscriptionId() {
      this.loadData()
    },
    organizationId() {
      this.loadData()
    },
    'addLicenceForm.subscriptionId'() {
      if(!this.isEditLicence) {
        this.addLicenceForm.subscriptionFormulaId = this.subscription.formulas?.length > 0 ? this.subscription.formulas[0].id : null
        this.addLicenceForm.subscriptionOptions = []
      }
    },

    billingReceiptType(type) {
      if(type === 'member') {
        this.addLicenceForm.billingOrganizationId = null
      } else {
        this.addLicenceForm.billingMemberId = null
      }
    },

    /**
     * Sets the billing start date for the addLicenceForm based on the startBillingAt value and subscription billing cycle.
     *
     * @return {void}
     */
    'addLicenceForm.startBillingAt'() {
      this.addLicenceForm.billingStartDateAsFirstChargeDate = false
      if(this.addLicenceForm.startBillingAt === 'immediately') {
        this.addLicenceForm.billingStartDate = dayjs().format('YYYY-MM-DD')
      } else if(this.addLicenceForm.startBillingAt === 'next-period') {

        this.addLicenceForm.billingStartDate = dayjs().add(1, 'month').startOf("month").format('YYYY-MM-DD')

        // If billing depend on the subscription billing cycle ?

        // let currentYear = dayjs().year();
        // let currentMonth = dayjs().month()
        // if(this.subscription.billingCycle === 'ANNUALLY') {
        //   this.addLicenceForm.billingStartDate = dayjs().add(1, 'year').startOf("year").format('YYYY-MM-DD')
        // } else if(this.subscription.billingCycle === 'BIANNUAL') {
        //   if(currentMonth < 6) {
        //     this.addLicenceForm.billingStartDate = currentYear + '-07-01'
        //   } else {
        //     this.addLicenceForm.billingStartDate = dayjs().add(1, 'year').startOf("year").format('YYYY-MM-DD')
        //   }
        // } else if(this.subscription.billingCycle === 'MONTHLY') {
        //   this.addLicenceForm.billingStartDate = dayjs().add(1, 'month').startOf("month").format('YYYY-MM-DD')
        // } else if(this.subscription.billingCycle === 'QUARTERLY') {
        //   if(currentMonth < 3) {
        //     this.addLicenceForm.billingStartDate = currentYear + '-04-01'
        //   } else if(currentMonth < 6) {
        //     this.addLicenceForm.billingStartDate = currentYear + '-07-01'
        //   } else if(currentMonth < 9) {
        //     this.addLicenceForm.billingStartDate = currentYear + '-10-01'
        //   } else {
        //     this.addLicenceForm.billingStartDate = dayjs().add(1, 'year').startOf("year").format('YYYY-MM-DD')
        //   }
        // }
      } else if(this.addLicenceForm.startBillingAt === 'first-charge-date') {
        this.addLicenceForm.billingStartDate = null
        this.addLicenceForm.billingStartDateAsFirstChargeDate = true
      }
    },

    selectedDeviceType() {
      this.chargingPointIds = []
      this.meterDeviceIds = []
    }
  },

  computed: {

    billingStartDateAtItems() {
      const items = [
        {text: this.$t('subscription.immediately'), value: 'immediately'},
        {text: this.$t('subscription.next-period'), value: 'next-period'},
        {text: this.$t('subscription.custom-date'), value: 'custom'},
      ]

      if(['device', 'charging-point', 'energy-provider'].includes(this.subscription.licenceType) && this.selectedDeviceType === 'charging-point') {
        items.push({text: this.$t('subscription.first-charge-date'), value: 'first-charge-date'})
      }
      return items
    },

    headers() {
      if(this.showActiveLicence) {
        const headers = [
          { text: this.$t('name'), value: 'related.label', align: 'left'},
          { text: this.$tc('subscription.subscription'), value: 'name', },
          { text: this.$t('since'), value: 'activationStartDate' },
          { text: this.$t('subscription.billing-start-date'), value: 'billingStartDate' },
          { text: this.$t('configuration'), value: 'config', sortable: false, align: 'left'},
          { text: this.$t('price-ht'), value: 'price' },
          { text: '', value: 'actions', width: 200, sortable: false, },
        ]

        if(!this.organizationId && !this.subscriptionId) {
          headers.splice(0, 0, { text: this.$t('organization.organization'), value: 'organization.name', sortable: false, })
        }
        return headers
      } else {
        const headers = [
          { text: this.$t('name'), value: 'related.label', align: 'left'},
          { text: this.$tc('subscription.subscription'), value: 'name', },
          { text: this.$t('since'), value: 'activationStartDate' },
          { text: this.$t('subscription.unsubscribed-at'), value: 'unsubscribedAt' },
          { text: this.$t('configuration'), value: 'config', sortable: false, align: 'left'},
          { text: '', value: 'actions', width: 200, sortable: false, },
        ]

        if(!this.organizationId && !this.subscriptionId) {
          headers.splice(0, 0, { text: this.$t('organization.organization'), value: 'organization.name', sortable: false, })
        }
        return headers
      }


    },

    existingChargingPointIds() {
      let chargingPointIdBySubscriptionId = {}
      for(let licence of this.licences) {
        if(licence.active) {
          if (licence.related?.type === 'charging-point') {
            if (!chargingPointIdBySubscriptionId[licence.subscription.id]) {
              chargingPointIdBySubscriptionId[licence.subscription.id] = []
            }
            chargingPointIdBySubscriptionId[licence.subscription.id].push(licence.related.id)
          }
        }
      }

      return chargingPointIdBySubscriptionId
    },



    excludeChargingPointIds() {
      return this.existingChargingPointIds[this.addLicenceForm.subscriptionId]
    },

    existingMeterDeviceIds() {
      let meterDeviceIdBySubscriptionId = {}
      for(let licence of this.licences) {
        if(licence.active) {
          if(licence.related?.type === 'meter-device') {
            if(!meterDeviceIdBySubscriptionId[licence.subscription.id]) {
              meterDeviceIdBySubscriptionId[licence.subscription.id] = []
            }
            meterDeviceIdBySubscriptionId[licence.subscription.id].push(licence.related.id)
          }
        }
      }

      return meterDeviceIdBySubscriptionId
    },

    excludeMeterDeviceIds() {
      return this.existingMeterDeviceIds[this.addLicenceForm.subscriptionId]
    },

    subscriptions() {
      return this.subscriptionStruct?.filter(s => {
        if(this.chargingPointId) {
          return ['charging-point', 'device'].includes(s.licenceType)
        }
        if(this.memberId) {
          return ['member'].includes(s.licenceType)
        }
        return true
      })
    },

    licenceInactivesCount() {
      return this.licences.filter(l => !l.active).length
    },

    licencesFiltered() {
      let search = this.search?.trim().toLowerCase() ?? ""
      let licences = this.licences.filter(l => l.active === this.showActiveLicence)

      if(search) {
        licences = licences.filter(l => l.related?.label.toLowerCase().includes(search))
      }
      return licences
    },

    canSaveLicence() {
      if(!this.subscription) {
        return false
      }



      if(this.subscription.formulas.length > 0 && !this.addLicenceForm.subscriptionFormulaId) {
        return false
      }


      if(this.subscription.licenceType === 'energy-provider') {
        if(this.billingReceiptType === 'member' && !this.addLicenceForm.billingMemberId) {
          return false
        }
        if(this.billingReceiptType === 'organization' && !this.addLicenceForm.billingOrganizationId) {
          return false
        }
      }

      if(this.isEditLicence) {
        if (this.addLicenceForm.quantity < 0) {
          return false
        }

        return true
      }

      if(['device', 'charging-point', 'energy-provider'].includes(this.subscription.licenceType)) {
        return this.chargingPointIds.length > 0 || this.meterDeviceIds.length > 0
      }
      if(['organization'].includes(this.subscription.licenceType)) {
        return !!this.addLicenceForm.organizationId
      }
      if(['member'].includes(this.subscription.licenceType)) {
        return !!this.addLicenceForm.memberId
      }

      return false
    },

    subscription() {
      return this.subscriptionStruct?.find(s => s.id === this.addLicenceForm?.subscriptionId)
    },

    isEditLicence() {
      return !!this.addLicenceForm?.id
    },

    canSelectStartBillingType() {
      return !this.isEditLicence
    },

    canShowStartBillingDate() {
      return this.isEditLicence || ['custom', 'next-period'].includes(this.addLicenceForm.startBillingAt)
    },

    canSetStartBillingDate() {
      return this.isEditLicence || ['custom'].includes(this.addLicenceForm.startBillingAt)
    }

  },

  methods: {
    loadData() {

      this.loading = true
      SubscriptionRepository.searchLicences({
        organizationId: this.organizationId,
        subscriptionId: this.subscriptionId,
        memberId: this.memberId,
        chargingPointId: this.chargingPointId,
      })
      .then(response => {
          this.licences = response
          this.loading = false
          if(!this.showActiveLicence && this.licenceInactivesCount === 0) {
            this.showActiveLicence = true
          }
      }).catch(error => {
          this.loading = false
          this.notifyError(error)
      })
    },


    openAddLicenceForm() {
      this.addLicenceForm = {
        subscriptionId: null,
        activationStartDate: dayjs().format('YYYY-MM-DD'),
        startBillingAt: 'immediately',
        billingStartDateAsFirstChargeDate: false,
        quantity: 1,
      }
      if(this.subscriptionId) {
        this.addLicenceForm.subscriptionId = this.subscriptionId
      } else if (this.subscriptionStruct?.length > 0) {
        this.addLicenceForm.subscriptionId =  this.subscriptionStruct[0].id
      }

      this.addLicenceForm.subscriptionFormulaId = this.subscription.formulas?.length > 0 ? this.subscription.formulas[0].id : null
      this.addLicenceForm.subscriptionOptions = []

      this.chargingPointIds = []
      if(this.chargingPointId) {
        this.chargingPointIds = [this.chargingPointId]
      }
      this.showLicenceFormModal = true
    },
    openEditLicenceForm(item) {
      const licence = this.cloneObj(item)

      this.addLicenceForm = {
        id: licence.id,
        subscriptionId: licence.subscription?.id,
        subscriptionFormulaId: licence.formula?.id,
        subscriptionOptions: licence.options?.map(o => o.id),
        related: licence.related,
        subscribedAt: licence.subscribedAt,
        activationStartDate: licence.activationStartDate,
        billingStartDate: licence.billingStartDate,
        active: licence.active,
        quantity: licence.quantity,
      }

      if(licence.related) {
        if(licence.related.type === 'charging-point') {
          this.addLicenceForm.chargingPointId = licence.related.id
        }
        if(licence.related.type === 'member') {
          this.addLicenceForm.memberId = licence.related.id
        }
        if(licence.related.type === 'organization') {
          this.addLicenceForm.organizationId = licence.related.id
        }
      }
      if(licence.subscriptionPlan.licenceType === 'energy-provider') {
        if(licence.billingMemberId) {
          this.billingReceiptType = 'member'
          this.addLicenceForm.billingMemberId = licence.billingMemberId
        } else if(licence.billingOrganizationId) {
          this.billingReceiptType = 'organization'
          this.addLicenceForm.billingOrganizationId = licence.billingOrganizationId
        }
      }
      this.showLicenceFormModal = true
    },

    unsubscribe(id, date) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if(result) {
          this.showLoading(true)
          SubscriptionRepository.unregisterLicence(id, date).then(result => {
            this.notifySuccess(this.$t('subscription.licence-terminated'))
            this.showLoading(false)
            this.loadData()
            this.showUnsubscribeModal = false
            this.closeLicenceFormModal()
          }).catch(error => {
            this.showLoading(false)
            this.notifyError(error)
          })
        }
      })
    },



    cancelUnsubscription(item) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if(result) {
          this.showLoading(true)
          SubscriptionRepository.reactivateLicence(item.id).then(result => {
            this.showLoading(false)
            this.loadData()
          }).catch(error => {
            this.showLoading(false)
            this.notifyError(error)
          })
        }
      })
    },

    closeLicenceFormModal() {
      this.showLicenceFormModal = false
    },

    openUnsubscribeModal() {
      this.unsubscribeDate = dayjs().format('YYYY-MM-DD')
      this.showUnsubscribeModal = true
    },

    saveLicence() {

      if(this.$refs.form.validate()) {
        this.loadingSaveLicence = true
        if(this.isEditLicence) {
          SubscriptionRepository.updateLicence(this.addLicenceForm.id, this.addLicenceForm).then(result => {
            this.notifySuccess(this.$t('subscription.licence-added'))
            this.closeLicenceFormModal()
            this.loadingSaveLicence = false
            this.loadData()
          }).catch(error => {
            this.loadingSaveLicence = false
            this.notifyError(error)
          })
        } else {
          const promises = []
          if(this.selectedDeviceType === 'charging-point') {
            for(let chargingPointId of this.chargingPointIds) {
              const data = this.cloneObj(this.addLicenceForm)
              data.chargingPointId = chargingPointId
              let promise = SubscriptionRepository.registerLicence(data).catch(error => {
                this.loadingSaveLicence = false
                return {error}
              })
              promises.push(promise)
            }
          }
          if(this.selectedDeviceType === 'meter-device') {
            for(let meterDeviceId of this.meterDeviceIds) {
              const data = this.cloneObj(this.addLicenceForm)
              data.meterDeviceId = meterDeviceId
              let promise = SubscriptionRepository.registerLicence(data).catch(error => {
                this.loadingSaveLicence = false
                return {error}
              })
              promises.push(promise)
            }

          }
          Promise.all(promises).then(results => {
            let errorCount = 0
            for(let result of results) {
              if(result.error) {
                errorCount++
                this.notifyError(result.error)
              } else {
                this.chargingPointIds = this.chargingPointIds.filter(id => id !== result.params.id)
                this.$dialog.notify.success(this.$t('subscription.device-licence-added', result.params))
              }
            }
            if(errorCount === 0) {
              this.closeLicenceFormModal()
            }
            this.loadingSaveLicence = false
            this.loadData()
          })

        }
      } else {
        this.notifyError(this.$t('form-errors'))
      }
    },

    itemProperties(item) {
      const properties = item.subscriptionPlan.properties?.map(property => ({
        text: this.$t(`enums.subscription-plan-properties.${property}`),
        value: property,
      }))
      if(item.formula) {
        for(let property of item.formula.properties) {
          properties.push({
            text: this.$t(`enums.subscription-plan-properties.${property}`),
            value: property,
          })
        }
      }
      if(item.options?.length > 0) {
        for(let option of item.options) {
          for(let property of option.properties) {
            properties.push({
              text: this.$t(`enums.subscription-plan-properties.${property}`),
              value: property,
            })
          }
        }
      }
      return properties
    },


    itemFilters(item) {
      const filters = item.subscriptionPlan.filters?.map(filter => ({
        text: this.$t(`enums.subscription-plan-filters.${filter}`),
        value: filter,
      }))
      if(item.formula) {
        for(let filter of item.formula.filters) {
          filters.push({
            text: this.$t(`enums.subscription-plan-filters.${filter}`),
            value: filter,
          })
        }
      }
      return filters
    },


    loadSubscriptionStruct() {

      const data = {}
      if(this.subscriptionId) {
        data.subscriptionId = this.subscriptionId
      } else if(this.organizationId) {
        data.organizationId = this.organizationId
      } else if(this.chargingPointId) {
        data.chargingPointId = this.chargingPointId
      } else if(this.memberId) {
        data.memberId = this.memberId
      }

      SubscriptionRepository.struct(data).then(response => {
        this.subscriptionStruct = response
        this.loading = false
      }).catch(error => {
        this.loading = false
        this.notifyError(error)
      })
    },

  }
}
</script>


<style lang="scss">
  .subscription-licences-list {
    .v-select__selections, .v-input__control {
      min-height: 40px;
    }
  }
</style>
