import Vue from 'vue'

class MemberInvitationRepository {

    get(id) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member-invitation/' + id,
                method: 'get',
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    delete(id) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/organization/member-invitation/' + id,
                method: 'delete',
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    update(id, data) {
        console.log(data)
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/organization/member-invitation/' + id,
                method: 'put',
                data,
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    search(data) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member-invitation/search',
                method: 'post',
                data,
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    resendMemberInvitation(id) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: `/api/organization/members/resend-invitation/${id}`,
                method: 'post',
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default new MemberInvitationRepository()