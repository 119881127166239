<template>
  <div class="organization-search">


    <tokens-manager :organization-id="organization.id" />

  </div>
</template>

<script>
import TokensManager from "@/components/elements/TokensManager.vue";

export default {
  components: {TokensManager},
  data() {
    return {

    }
  },

  computed: {
    organization() {
      return this.$parent.organization ?? null
    }
  },

  watch: {

  },

  methods: {

  }
}
</script>

<style lang="scss">

</style>
