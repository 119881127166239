<template>
  <div class="organization-overview  pa-10">
    Invoices
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },

  mounted() {

  },

  watch: {

  },

  computed: {
    organization() {
      return this.$parent.organization ?? null
    }
  },

  methods: {

  },
}
</script>

<style lang="scss">


.organization-overview {

}

</style>
