import Vue from 'vue'

class EnergyProviderRepository {
  periods(organizationId, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/organization/${organizationId}/energy-provider-periods`,
        method: 'post',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
  peiodLines(periodId, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/organization/energy-provider/${periodId}/lines`,
        method: 'post',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
  lines(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/organization/energy-provider/lines`,
        method: 'post',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  exportLines(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: `/api/organization/energy-provider/lines/export-xls`,
        method: 'post',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
  period(periodId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/organization/energy-provider/${periodId}/period`,
        method: 'get',
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  syncPeriod(periodId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/organization/energy-provider/${periodId}/sync`,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  exportPeriodPdf(periodId, billableId) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: `/api/organization/energy-provider/${periodId}/export-pdf`,
        method: 'post',
        data: {
          billableId,
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}


export default new EnergyProviderRepository()
