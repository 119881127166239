<template>
  <div class="customer-detail">

    <v-breadcrumbs
        class="level-2"
        :items="[
            {text: $tc('member.member', 2), to: {name: 'organizations.members'}, exact: true},
            {text: $t('member-group.manage'), to: {name: 'organizations.members.groups'}, exact: true },
            {text: $t('member-group.' + (edit ? 'edit' : 'add')) },
        ]"
        divider=">"
    ></v-breadcrumbs>


    <v-form ref="form" @submit.prevent="save">

      <headline>
        {{ $tc("member-group.title") }}
      </headline>
      <v-row>
        <v-col>
          <form-field
              :label="$t('name')"
              v-model="form.name"
              col-field="9"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <form-field
              :label="$t('type')"
              v-model="form.type"
              type="select"
              :items="types"
              col-field="9"
              :disabled="edit"
          />
        </v-col>
      </v-row>
      <template v-if="form.customFields">
        <headline class="mt-10">
          {{ $tc("custom-field.custom-field", 2) }}
        </headline>
        <v-row>
          <v-col>
            <custom-fields
                v-model="form.customFields"
                :fields="form.customFieldsDefinition"
                :filter-query="{
                  organizationId: organization.id,
                  isCpoPrivate: true,
                }"
                :col-field="9"
            />
          </v-col>
        </v-row>
      </template>

      <v-row class="mt-10 mb-4">
        <v-col class="text-center">
          <v-btn color="primary" type="submit">
            {{ $t(edit ? 'update' : 'create') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <template v-if="form?.id">
      <headline class="mt-10">
        {{ $t('member-group.members') }}
      </headline>
      <v-row>
        <v-col class="text-right">
          <!-- Modal: Invitation membre -->
          <members-groups-associations
              :memberGroup="form.id"
              :organization-id="organization.id"
              from="memberGroupForm"
          ></members-groups-associations>
        </v-col>
      </v-row>
    </template>

  </div>
</template>

<script>
import RulesMixin from "@mixins/RulesMixin";
import MemberGroupRepository from "@repository/MemberGroupRepository";
import CustomFields from "@blocks/CustomFields.vue";
import MembersGroupsAssociations from "@/components/modals/MembersGroupsAssociations.vue";
import {EventBus} from "@includes/EventBus";

export default {
  components: { CustomFields, MembersGroupsAssociations },

  data() {
    return {
      types: [],
      form: {
        name: null,
      }
    }
  },

  mixins: [
    RulesMixin,
  ],

  mounted() {
    if (!this.currentUser.permissions.includes('ORGANIZATION_WRITE')) {
      this.$router.replace({name: 'dashboard'})
      return
    }
    if (this.edit) {
      this.loadMemberGroup()
    }

    this.loadMemberGroupTypes()

  },

  watch: {
    edit() {
      this.loadMemberGroup()
    }
  },

  computed: {

    organization() {
      return this.$parent.organization ?? null
    },

    edit() {
      return !!this.id
    },

    id() {
      return this.$route.params.memberGroupId ?? null
    },
  },

  methods: {
    loadMemberGroup() {
      this.showLoading(true)
      MemberGroupRepository.get(this.id).then(data => {
        this.form = data
        this.showLoading(false)
      }).catch(err => {
        this.$dialog.notify.error(err.response.data?.message || err.message)
        this.showLoading(false)
      })
    },

    loadMemberGroupTypes() {
      MemberGroupRepository.types().then(data => {
        this.types = data.map(d => ({text: this.$t('member-group.types.' + d), value: d}))
      }).catch(err => {
        this.$dialog.notify.error(err.response.data?.message || err.message)
      })
    },

    save() {
      if (this.$refs.form.validate()) {
        this.showLoading(true)
        let data = this.form
        if (this.edit) {
          MemberGroupRepository.update(this.id, data).then(result => {
            this.showLoading(false)
            this.$router.push({name: 'organizations.members.groups'})
            this.$dialog.notify.success(this.$t('update-success'))
            EventBus.$emit('reloadView')
          }).catch(error => {
            this.showLoading(false)
            this.$dialog.notify.error(error.response?.data?.message ?? error.message)
          })
        } else {
          data.organizationId = this.organization.id

            MemberGroupRepository.create(data).then(result => {
              this.$dialog.notify.success(this.$t('create-success'))
              this.showLoading(false)
              this.$router.push({name: 'organizations.members.groups.edit', params: {memberGroupId: result.id}})
            }).catch(error => {
              this.showLoading(false)
              this.$dialog.notify.error(error.response?.data?.message ?? error.message)
            })
        }
      }
    }
  }
}
</script>

<style lang="scss">

</style>
