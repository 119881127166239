<template>
  <v-dialog width="70%" v-model="dialog">
    <v-form ref="form" class="form-grid" @submit.prevent="subscribe">
      <v-card>
        <v-card-title>
          {{ $t('subscription.add') }}
        </v-card-title>
        <v-card-text>

          <headline>{{ $t('organization.general-information') }}</headline>
          <v-row>
            <v-col cols="12">
              <form-field
                  type="select"
                  v-model="form.subscriptionPlan"
                  :items="subscriptionPlans"
                  :label="$tc('subscription.subscription-plan')"
                  :rules="[
                      commonRules.required,
                  ]"
                  :col-field="9"
                  item-text="name"
                  return-object
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-field
                  type="select"
                  v-model.number="form.billingCycle"
                  :items="billingCycles"
                  :label="$tc('subscription.billing-cycle')"
                  :rules="[
                      commonRules.required,
                  ]"
              >
              </form-field>
            </v-col>
            <v-col cols="12" md="6">
              <form-field
                  type="select"
                  v-model.number="form.commitmentDuration"
                  :label="$tc('subscription.commitment-duration')"
                  :items="[
                      6,
                      12,
                      24,
                      36,
                      48
                  ]"
                  :col-field="8"
                  :rules="[
                      commonRules.required,
                  ]"
              >
                <template #append>
                  {{ $t('month') }}
                </template>
              </form-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <form-field
                  type="date"
                  v-model="form.activationStartDate"
                  :label="$tc('subscription.activation-start-date')"
                  :rules="[
                      commonRules.required,
                  ]"
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-field
                  type="date"
                  v-model="form.billingStartDate"
                  :label="$tc('subscription.billing-start-date')"

                  :rules="[
                      commonRules.required,
                  ]"
              />
            </v-col>
          </v-row>

          <v-row v-if="form.subscriptionPlan?.customFieldsDefinition">
            <v-col>
              <custom-fields
                v-model="form.customFields"
                :fields="form.subscriptionPlan.customFieldsDefinition"
                :col-field="9"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider class="mt-6"></v-divider>

        <v-card-actions>
          <v-row class="ma-0">
            <v-col class="text-right">
              <v-btn small @click="close">
                {{ $t('cancel') }}
              </v-btn>
              <v-btn small class="ml-3" color="primary" type="submit">
                {{ $t('subscription.subscribe') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>

import SubscriptionRepository from "@repository/SubscriptionRepository";
import CustomFields from "@blocks/CustomFields.vue";
import RulesMixin from "@mixins/RulesMixin";
import dayjs from "dayjs";

export default {

  components: {
    CustomFields,
  },

  data() {
    return {
      dialog: false,
      headers: [
        {text: this.$tc('subscription.subscription-plan'), value: 'name'},
        {text: '', value: 'options', width: 200},
      ],
      subscriptionPlans: [],
      form: {
        subscriptionPlan: null,
        billingCycle: 'MONTHLY',
        commitmentDuration: 12,
        refundHandling: true,
        activationStartDate: dayjs().format('YYYY-MM-DD'),
        billingStartDate: dayjs().format('YYYY-MM-DD'),
        customFields: [],
      },
    };
  },

  props: {
    organizationId: String,
  },

  mixins: [
      RulesMixin,
  ],

  mounted() {
    this.loadData()
  },

  destroyed() {

  },

  watch: {
    'form.subscriptionPlan': function(sp) {
      this.form.customFields = sp.customFields
    }
  },

  computed: {
    billingCycles() {
      const items = []
      let types = this.$t('enums.billing-cycle');
      for(let type in types) {
        items.push({text: types[type], value: type})
      }
      return items
    }
  },

  methods: {

    open() {
      this.dialog = true
    },

    close() {
      this.dialog = false
    },

    loadData() {
      SubscriptionRepository.searchPlans({}).then(data => {
        this.subscriptionPlans = data.items
      })
    },

    subscribe() {
      if(this.$refs.form.validate()) {
        this.showLoading(true)
        const data = this.cloneObj(this.form)
        data.subscriptionPlanId = data.subscriptionPlan?.id
        delete data.subscriptionPlan
        SubscriptionRepository.organizationSubscribe(this.organizationId, data).then(response => {
          this.showLoading(false)
          this.close()
          this.$emit('subscribed', response.data)
        }).catch(err => {
          this.$dialog.notify.error(err.response?.data?.message || err.message)
          this.showLoading(false)
        })
      }
    }

  }
}
</script>

<style lang="scss">

</style>
