<template>
  <div class="member-search ">

    <v-breadcrumbs
        :items="breadcrumbs"
        divider=">"
    ></v-breadcrumbs>

    <v-row dense>
      <v-col class="text-right" v-if="billables.length > 1">
        <v-select
          :label="$tc('subscription.licence')"
          v-model="billableId"
          :items="billables"
          item-text="billableItem.name"
          item-value="billableItem.id"
        ></v-select>
      </v-col>
      <v-col class="text-right">
        <v-btn small @click="syncPeriod" v-if="period && !period?.billed">
          <v-icon left>mdi-sync</v-icon>
          Sync CDR
        </v-btn>
        <v-btn small class="ml-3" @click="exportPdf">
          <v-icon left>mdi-file-pdf-box</v-icon>
          PDF
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mt-10" v-if="period">
      <v-col cols="6">
        <v-card>
          <v-card-text>

            <v-row dense>
              <v-col cols="4">
                {{ $t('expense-refund.period') }}
              </v-col>
              <v-col cols="8">
                {{ periodName }}
              </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>

            <v-row dense>
              <v-col cols="4">
                {{ $t('expense-refund.charge-count') }}
              </v-col>
              <v-col cols="8">
                {{ periodStats.nbLines }}
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card>
          <v-card-text>

            <v-row dense>
              <v-col cols="4">
                {{ $t('charging-point.consumed-energy') }}
              </v-col>
              <v-col cols="8">
                {{ numberFormat(periodStats.energy/1000, 3) }} kWh
              </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>

            <v-row dense>
              <v-col cols="4">
                <b>{{ $t('amount') }}</b>
              </v-col>
              <v-col cols="8">
                <b>{{ numberFormat(periodStats.amount) }} €</b>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    <!-- List -->
    <v-row no-gutters class="mt-10">
      <v-col>
        <v-data-table
            :headers="headers"
            :items="lines"
            :server-items-length="periodStats.nbLines"
            :options.sync="options"
            :loading="loading"
        >

          <template #item.startTime="{item}">
            {{ item.startTime|formatDate }}
          </template>

          <template #item.chargingPoint.name="{item}">
            <router-link :to="{name: 'charging-points.detail', params: {id: item.chargingPoint.id}}">
              {{ item.chargingPoint.name }}
            </router-link>
          </template>

          <template #item.stopTime="{item}">
            {{ item.stopTime|formatDate }}
          </template>

          <template #item.duration="{value}">
            {{ formatDuration(value) }}
          </template>

          <template #item.member="{value}">
            <router-link v-if="value?.lastname" :to="{name: 'member', params: {memberId: value.id}}">
              {{  value.firstname + ' ' + value.lastname }}
            </router-link>
            <template v-else>
              {{ value.evcoId }}
            </template>
          </template>

          <template #item.energy="{value}">
            {{ numberFormat(value/ 1000, 3) }} kWh
          </template>

          <template #item.amount="{value}">
            {{ numberFormat(value) }} €
          </template>

          <template #item.status="{item}">
            <v-select
                v-model="item.status"
                :items="cdrStatues"
                hide-details
                @change="changeStatus(item)"
                :disabled="!isEditable"
            >
            </v-select>
          </template>

        </v-data-table>

      </v-col>
    </v-row>







  </div>
</template>

<script>
import EnergyProviderRepository from "@repository/EnergyProviderRepository";

export default {
  data() {
    return {
      loading: false,
      period: null,
      billableId: this.$route.query.bId ?? null,
      billables: [],
      total: 0,
      lines: [],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['stopTime'],
        sortDesc: [false],
      },
    }
  },

  mounted() {
    this.loadBillables()
  },

  watch: {
    options: {
      handler() {
        this.loadData()
      },
      deep: true,
    },

    billableId(val) {
      if(val) {
        this.$router.push({query: {bId: val}})
      } else {
        this.$router.push({query: {}})
      }
    },

    '$route.query.bId'() {
      this.loadData()
    },

  },

  computed: {

    breadcrumbs() {
      const items = [
        { text: 'Service de recharge', to: {name: 'organizations.energy-provider' }, exact: true},
      ]
      if(this.billable?.billableItem) {
        items.push({ text: this.periodName, to: {name: 'organizations.energy-provider.period', params: {periodId: this.periodId}}, exact: true },)
        items.push({ text: this.billable.billableItem.name })
      } else {
        items.push({ text: this.periodName, to: {name: 'organizations.energy-provider.period', params: {periodId: this.periodId}}, exact: true, disabled: true },)

        if(this.billables.length > 0) {
          items.push({text: this.billables[0].billableItem.name})
        }
      }
      return items
    },

    periodStats() {
      return this.billable ?? this.period ?? null
    },

    billable() {
      if(this.$route.query.bId) {
        return this.billables.find(b => b.billableItem.id === this.$route.query.bId)
      }
      if(this.billables.length > 0) {
        return this.billables[0]
      }
      return this.period ?? {}
    },

    headers() {
      return [
        {text: this.$t('expense-refund.charging-date'), value: 'startTime'},
        {text: this.$t('charging-point.charging-point'), value: 'chargingPoint.name'},
        {text: this.$tc('member.member'), value: 'member'},
        {text: this.$t('energy'), value: 'energy'},
        {text: this.$t('duration'), value: 'duration'},
        {text: this.$t('net'), value: 'amount'},
      ]
    },

    organization() {
      return this.$parent.organization ?? null
    },


    periodId() {
      return this.$route.params.periodId
    },

    periodName() {
      if(this.period?.startDate) {
        return this.formatUtcDate(this.period.startDate) + ' > ' + this.formatUtcDate(this.period.endDate)
      }
      return null
    },

  },

  methods: {

    loadData() {
      return new Promise((resolve, reject) => {
        this.showLoading(true)

        const data = {}

        if(this.$route.query.bId) {
          data.billableId = this.$route.query.bId
        }

        if (this.options.itemsPerPage > -1) {
          data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
        }
        data.limit = this.options.itemsPerPage

        if (this.options.sortBy.length > 0) {
          data.orderBy = this.options.sortBy[0]
        }

        if (this.options.sortDesc.length > 0) {
          data.orderDirection = this.options.sortDesc[0] ? 'desc' : 'asc'
        }

        EnergyProviderRepository.peiodLines(this.periodId, data).then(response => {
          this.period = response.period
          this.lines = response.lines
          this.showLoading(false)
          resolve(response)
        }).catch(err => {
          this.notifyError(err)
          this.loading = false
          reject(err)
        })
      })
    },

    loadBillables() {
      return new Promise((resolve, reject) => {
        EnergyProviderRepository.period(this.periodId).then(response => {
          this.billables = response.items
          resolve(response)
        }).catch(err => {
          this.notifyError(err)
          reject(err)
        })
      })
    },


    syncPeriod() {
      this.showLoading(true)
      EnergyProviderRepository.syncPeriod(this.periodId).then(() => {
        this.loadData().then(() => {
          this.notifySuccess(this.$t('sync-success'))
        })
      }).catch(err => {
        this.showLoading(false)
        this.notifyError(err)
      })
    },

    exportPdf() {
      this.showLoading(true)
      EnergyProviderRepository.exportPeriodPdf(this.periodId, this.billable?.billableItem?.id).then(() => {
        this.showLoading(false)
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
        this.showLoading(false)
      })
    },


  },
}
</script>

<style lang="scss">
.member-search {
  min-height: 1000px;
  .member-list {
    td, th {
      cursor: pointer;
      &.text-start {
        text-align: left !important;
      }
    }
  }
}
</style>
