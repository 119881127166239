var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"70%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",staticClass:"form-grid",on:{"submit":function($event){$event.preventDefault();return _vm.subscribe.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('subscription.add'))+" ")]),_c('v-card-text',[_c('headline',[_vm._v(_vm._s(_vm.$t('organization.general-information')))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('form-field',{attrs:{"type":"select","items":_vm.subscriptionPlans,"label":_vm.$tc('subscription.subscription-plan'),"rules":[
                    _vm.commonRules.required,
                ],"col-field":9,"item-text":"name","return-object":""},model:{value:(_vm.form.subscriptionPlan),callback:function ($$v) {_vm.$set(_vm.form, "subscriptionPlan", $$v)},expression:"form.subscriptionPlan"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('form-field',{attrs:{"type":"select","items":_vm.billingCycles,"label":_vm.$tc('subscription.billing-cycle'),"rules":[
                    _vm.commonRules.required,
                ]},model:{value:(_vm.form.billingCycle),callback:function ($$v) {_vm.$set(_vm.form, "billingCycle", _vm._n($$v))},expression:"form.billingCycle"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('form-field',{attrs:{"type":"select","label":_vm.$tc('subscription.commitment-duration'),"items":[
                    6,
                    12,
                    24,
                    36,
                    48
                ],"col-field":8,"rules":[
                    _vm.commonRules.required,
                ]},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('month'))+" ")]},proxy:true}]),model:{value:(_vm.form.commitmentDuration),callback:function ($$v) {_vm.$set(_vm.form, "commitmentDuration", _vm._n($$v))},expression:"form.commitmentDuration"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('form-field',{attrs:{"type":"date","label":_vm.$tc('subscription.activation-start-date'),"rules":[
                    _vm.commonRules.required,
                ]},model:{value:(_vm.form.activationStartDate),callback:function ($$v) {_vm.$set(_vm.form, "activationStartDate", $$v)},expression:"form.activationStartDate"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('form-field',{attrs:{"type":"date","label":_vm.$tc('subscription.billing-start-date'),"rules":[
                    _vm.commonRules.required,
                ]},model:{value:(_vm.form.billingStartDate),callback:function ($$v) {_vm.$set(_vm.form, "billingStartDate", $$v)},expression:"form.billingStartDate"}})],1)],1),(_vm.form.subscriptionPlan?.customFieldsDefinition)?_c('v-row',[_c('v-col',[_c('custom-fields',{attrs:{"fields":_vm.form.subscriptionPlan.customFieldsDefinition,"col-field":9},model:{value:(_vm.form.customFields),callback:function ($$v) {_vm.$set(_vm.form, "customFields", $$v)},expression:"form.customFields"}})],1)],1):_vm._e()],1),_c('v-divider',{staticClass:"mt-6"}),_c('v-card-actions',[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"small":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('subscription.subscribe'))+" ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }