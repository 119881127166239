<template>
  <div>
    <v-dialog width="70%" v-model="displayed" scrollable>
      <v-card class="cpo-private-accesses-modal">
        <v-toolbar
            color="#eee"
            height="48px"
        >
          <v-toolbar-title>{{ $t('member.invite-member') }} : {{ organization.name }}</v-toolbar-title>
          <v-spacer/> <!-- Affiche le bouton à droite de la barre -->
          <v-btn
              icon
              @click="displayed = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form @submit.prevent="inviteCustomer">
          <v-card-text>
            <v-row class="my-5 ">
              <v-col class="text-right center">
                <search-field
                    solo
                    :search.sync="searchCustomer"
                    :placeholder="$t('customer.type-address-or-phone')"
                    prepend-inner-icon="ico-magnify"
                >
                  <template #item="{item}">
                    <v-row class="item">
                      <v-col class="name">
                        {{ item.firstname }} {{ item.lastname }} {{ item.email }}
                      </v-col>
                    </v-row>
                  </template>

                  <template #selection="{item}">
                    {{ item.firstname }} {{ item.lastname }} {{ item.email }}
                  </template>
                </search-field>
              </v-col>
            </v-row>

          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-row class="ma-0">
              <v-col class="text-right">
                <v-btn type="button" small @click="displayed = false">
                  {{ $t('cancel') }}
                </v-btn>
                <v-btn type="submit" class="ml-3" small color="primary" :disabled="!searchCustomer || searchCustomer.trim() === ''">
                  {{ $t('add') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import OrganizationRepository from "@repository/OrganizationRepository";
import {EventBus} from "@includes/EventBus";
import CustomerRepository from "@repository/CustomerRepository";
import Validator from "@/service/Validator";

export default {

  components: {},

  data() {
    return {
      displayed: false,
      searchCustomer: null,
      loadingSearchCustomer: false,
    };
  },

  props: {
    organization: Object,
  },

  mounted() {


  },

  destroyed() {

  },

  watch: {},

  computed: {
    Validator() {
      return Validator
    }
  },

  methods: {
    open() {
      this.searchCustomer = null
      this.displayed = true
    },

    inviteCustomer() {
        this.showLoading(true)
        OrganizationRepository.inviteCustomer(this.organization.id, this.searchCustomer).then((invitationId) => {
          this.searchCustomer = null
          this.displayed = false
          this.showLoading(false)
          this.$emit('invitationSent')

          // Go to the invitation page
          this.$router.push({name: 'organizations.members.invitations.manage', params: {invitationId} })
        }).catch(error => {
          this.showLoading(false)
          this.notifyError(error)
        })
    },
  }
}
</script>

<style lang="scss">

</style>
