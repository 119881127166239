import Vue from 'vue'

class MemberGroupRepository {

    get(id) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/member-group/get/' + id,
                method: 'get',
            })
                .then(response => {
                   // console.log(response)
                    resolve(response.data)
                }).catch(err => {
                reject(err)
            })
        })
    }
    create(data) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/member-group/create',
                method: 'post',
                data,
            })
                .then(response => {
                    resolve(response.data)
                }).catch(err => {
                reject(err)
            })
        })
    }

    update(id, data) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/member-group/update/' + id,
                method: 'put',
                data,
            })
                .then(response => {
                    resolve(response.data)
                }).catch(err => {
                reject(err)
            })
        })
    }

    search(data) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/member-group/search',
                method: 'post',
                data,
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    delete(id) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: `/api/member/member-group/delete/${id}`,
                method: 'delete',
            })
                .then(response => {
                    resolve(response.data)
                }).catch(err => {
                reject(err)
            })
        })
    }

  types() {
      return new Promise((resolve, reject) => {
          Vue.auth.fetch({
              url: '/api/member/member-group/types',
              method: 'get',
          }).then(response => {
              resolve(response.data)
          }).catch(err => {
              reject(err)
          })
      })
  }
}

export default new MemberGroupRepository()