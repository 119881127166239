<template>
  <div class="organization-tariffs">

    <v-row >
      <v-col class="text-right">
        <v-btn @click="createTariff" small>
          <v-icon left>mdi-plus</v-icon>
          {{ $t('tariff.add-tariff')}}
        </v-btn>
      </v-col>
    </v-row>


    <v-row no-gutters class="mt-10">
      <v-col>
        <v-data-table
            class="cp-list"
            :headers="headers"
            :items="items"
            :loading="loading"
            :options.sync="options"
            :server-items-length="total"
        >

          <template #item.rules="{item}">
            {{ $tc('tariff.rule', item.rules.length)}}
          </template>

          <template #item.powerType="{item}">
            {{ item.powerType ? $t('enums.power-type.' + item.powerType) : '-'}}
          </template>

          <template #item.isCpoPrivate="{value}">
            <v-icon v-if="value">mdi-check</v-icon>
          </template>

          <template #item.options="{item}">
            <v-btn icon color="primary" small @click="editTariff(item)" v-if="!item.inherit">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <i class="grey--text" v-else>
              {{ $t('inherit') }}
            </i>
          </template>
        </v-data-table>
      </v-col>
    </v-row>



    <tariff-modal
        ref="tariffModal"
        v-if="organization"
        :organizationId="organization.id"
        :tariffId="selectedTariffId"
        @tariff-updated="onTariffUpdated"
    />


  </div>
</template>

<script>


import CustomerRepository from "@repository/CustomerRepository";
import TariffRepository from "@repository/TariffRepository";
import TariffModal from "@/components/modals/TariffModal.vue";

export default {
  name: 'CustomerSearch',
  components: {TariffModal},
  data() {
    return {
      loading: false,
      search: null,
      items: [],
      total: 0,
      options: null,
      selectedTariffId: null,
    }
  },

  mounted() {

  },

  watch: {
    search(val) {
      if(this.options.page > 1) {
        this.options.page = 1
      } else {
        this.searchData()
      }
    },

    options(val) {
      this.searchData()
    }
  },

  computed: {
    headers() {
      return [
        {text: this.$t('name'), value: 'name'},
        {text: this.$t('network'), value: 'network'},
        {text: this.$t('charging-point.power-type'), value: 'powerType'},
        {text: this.$t('organization.cpo-private'), value: 'isCpoPrivate'},
        {text: this.$t('tariff.rules'), value: 'rules'},
        {text: '', value: 'options'},
      ]
    },



    organization() {
      return this.$parent.organization ?? null
    }
  },

  methods: {
    searchData() {

      let options = this.options



      this.loading = true
      let query = this.search;
      let offset = options.itemsPerPage * options.page - options.itemsPerPage;
      let limit = options.itemsPerPage;
      let sortBy = options.sortBy.length > 0 ? options.sortBy[0] : 'createdAt'
      let sortOrder = options.sortDesc.length > 0 && options.sortDesc[0] ? 'desc' : 'asc'


      if(sortBy === 'tokens') {
        sortBy = 'tokens.uid'
      }

      this.loading = true
      TariffRepository.search({
            query,
            limit,
            offset,
            sortBy,
            sortOrder,
            organizationId: this.organization?.id,
      }).then(result => {
        this.total = result.total
        this.items = result.items
        this.loading = false
      }).catch(err => {
        this.$dialog.notify.error(this.$t(err.response?.data?.message || err.message))
        this.loading = false
      })
    },

    createTariff() {
      this.selectedTariffId = null
      this.$nextTick(() => {
        this.$refs.tariffModal.open()
      })
    },

    editTariff(tariff) {
      this.selectedTariffId = tariff.id
      this.$nextTick(() => {
        this.$refs.tariffModal.open()
      })
    },

    onTariffUpdated() {
      if(this.options.page > 1) {
        this.options.page = 1
      } else {
        this.searchData()
      }
    },
  }
}
</script>

<style lang="scss">
.organization-tariffs {
  .v-data-table {
    td {
      cursor: pointer;
      .click{
        text-decoration: none;
        color: #71A723;
      }
      .number {
        color: #E53C22;
        font-weight: bold;
      }

      .company {
        font-weight: 100;
      }
    }
  }
}


</style>
